import React, { useEffect } from "react";
import "./index.css";
import user from "../../assets/images/png/user.png";
import grafico from "../../assets/images/png/grafico.png";
import presente from "../../assets/images/png/presente.png";
import home from "../../assets/images/png/home.png";
import recibo from "../../assets/images/png/recibo.png";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export default function Footer() {
  const locate = useLocation();
  const url = locate.pathname;
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="divFooterExterna">
          <div className="divFooter">
            <Link
              to={"/catalogo"}
              className={url.includes("catalogo") ? "menuAtivo" : "menuInativo"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.86"
                height="16.86"
                viewBox="0 0 16.86 16.86"
              >
                <path
                  id="Caminho_7911"
                  data-name="Caminho 7911"
                  d="M115.182,47.933a2.676,2.676,0,0,0-2.573-2.667,2.658,2.658,0,0,0,.274-1.16,2.686,2.686,0,0,0-2.682-2.682,3.95,3.95,0,0,0-3.449,2.406,3.949,3.949,0,0,0-3.449-2.406,2.686,2.686,0,0,0-2.682,2.682,2.647,2.647,0,0,0,.275,1.16,2.671,2.671,0,0,0-1.041,5.086v4.992a2.942,2.942,0,0,0,2.938,2.939h7.917a2.942,2.942,0,0,0,2.939-2.939V50.353A2.682,2.682,0,0,0,115.182,47.933ZM110.2,42.957a1.15,1.15,0,0,1,0,2.3h-2.417C108.144,44.3,108.888,42.957,110.2,42.957Zm-6.9,0c1.313,0,2.057,1.348,2.418,2.3H103.3a1.15,1.15,0,0,1,0-2.3ZM101,46.788h4.986v2.3H101a1.15,1.15,0,0,1,0-2.3Zm.388,8.556V50.62h4.6v6.131h-3.193A1.408,1.408,0,0,1,101.387,55.345Zm9.323,1.406h-3.192V50.62h4.6v4.725A1.408,1.408,0,0,1,110.71,56.751Zm1.793-7.664h-4.985v-2.3H112.5a1.15,1.15,0,0,1,0,2.3Z"
                  transform="translate(-98.322 -41.424)"
                  fill={url.includes("catalogo") ? "#000000" : "#cfdcdf"}
                />
              </svg>
            </Link>
            <Link
              to={"/home"}
              className={url.includes("home") ? "menuAtivo" : "menuInativo"}
            >
              <div>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.36365 7.61377H18.6364C19.6029 7.61377 20.3864 8.39727 20.3864 9.36377V18.6365C20.3864 19.603 19.6029 20.3865 18.6364 20.3865H9.36365C8.39715 20.3865 7.61365 19.603 7.61365 18.6365V9.36377C7.61365 8.39727 8.39715 7.61377 9.36365 7.61377Z"
                    stroke={url.includes("home") ? "#000000" : "#cfdcdf"}
                    strokeWidth="2.5"
                  />
                  <circle
                    cx="14"
                    cy="14"
                    r="12.75"
                    stroke={url.includes("home") ? "#000000" : "#cfdcdf"}
                    strokeWidth="2.5"
                  />
                </svg>
              </div>
            </Link>
            <Link
              to={"/meu-cadastro"}
              className={
                url.includes("meu-cadastro") ? "menuAtivo" : "menuInativo"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.327"
                height="19.059"
                viewBox="0 0 17.327 19.059"
              >
                <g
                  id="Grupo_4134"
                  data-name="Grupo 4134"
                  transform="translate(1.121 1.1)"
                >
                  <g
                    id="Grupo_4125"
                    data-name="Grupo 4125"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Caminho_1955"
                      data-name="Caminho 1955"
                      d="M18.206,5.715A4.215,4.215,0,1,1,13.991,1.5,4.215,4.215,0,0,1,18.206,5.715Z"
                      transform="translate(-6.448 -1.5)"
                      fill="none"
                      stroke={
                        url.includes("meu-cadastro") ? "#000000" : "#cfdcdf"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.2"
                    />
                    <path
                      id="Caminho_1956"
                      data-name="Caminho 1956"
                      d="M14.855,32.734a1.713,1.713,0,0,0,1.583-2.388,8.095,8.095,0,0,0-14.792,0,1.713,1.713,0,0,0,1.583,2.388Z"
                      transform="translate(-1.5 -15.874)"
                      fill="none"
                      stroke={
                        url.includes("meu-cadastro") ? "#000000" : "#cfdcdf"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.2"
                    />
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
