import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";
import PDF from "../../Components/Pdf";
import ppp from "../../assets/pdf/hyundai.pdf";

export default function ComoFunciona() {
  const { load, setLoad } = useLoad();
  const [comoFunciona, setComoFunciona] = useState([]);

  const navigate = useNavigate();

  async function fnListarComoFunciona() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("como-funciona/portal");

      setComoFunciona(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarComoFunciona();
  }, []);
  return (
    <>
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/home"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Como funciona</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-3"></div>
        </div>
      </div>

      <div className="d-flex justify-content-center px-3 mt-3 ">
        <div className="d-flex flex-column w-100" style={{ maxWidth: 500 }}>
          {comoFunciona.map((e, i) => (
            <div className="px-3" key={i}>
              <div className="institucionalTexto3">{e.titulo}</div>
              <div className="institucionalTexto4">{e.conteudo}</div>
              <button
                className=" btn-intitucional"
                onClick={() => {
                  navigate("/como-funciona/detalhe", {
                    state: { url: e.urlArquivo },
                  });
                }}
              >
                Ver mais
              </button>
              {comoFunciona.length != i + 1 && <hr />}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
