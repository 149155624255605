export function validaDataNascimento(dataNascimento) {
  // Expressão regular para verificar o formato da data (DDMMYYYY)
  var regexData = /^\d{8}$/;

  if (!regexData.test(dataNascimento)) {
    return false; // Formato da data inválido
  }

  // Extrai dia, mês e ano da data
  var dia = parseInt(dataNascimento.substring(0, 2), 10);
  var mes = parseInt(dataNascimento.substring(2, 4), 10) - 1; // Meses em JavaScript são de 0 a 11
  var ano = parseInt(dataNascimento.substring(4, 8), 10);

  // Cria um objeto Date e verifica se é uma data válida
  var data = new Date(ano, mes, dia);

  // Obtém a data atual
  var dataAtual = new Date();

  // Define a data máxima permitida (120 anos atrás)
  var dataMaxima = new Date();
  dataMaxima.setFullYear(dataMaxima.getFullYear() - 120);

  return (
    data.getDate() === dia &&
    data.getMonth() === mes &&
    data.getFullYear() === ano &&
    data <= dataAtual &&
    data >= dataMaxima
  );
}