import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import camera from "../../assets/images/png/camera.png";
import sair from "../../assets/images/png/sair.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import ForcaSenha from "../../Components/ForcaSenha";
import { toast } from "react-toastify";
import moment from "moment";
import Menu from "../../Components/Menu";
import avatar1 from "../../assets/images/avatares/avatar (1).png";
import avatar2 from "../../assets/images/avatares/avatar (2).png";
import avatar3 from "../../assets/images/avatares/avatar (3).png";
import avatar4 from "../../assets/images/avatares/avatar (4).png";
import avatar5 from "../../assets/images/avatares/avatar (5).png";
import avatar6 from "../../assets/images/avatares/avatar (6).png";
import avatar7 from "../../assets/images/avatares/avatar (7).png";
import avatar8 from "../../assets/images/avatares/avatar (8).png";
import avatar9 from "../../assets/images/avatares/avatar (9).png";
import avatar10 from "../../assets/images/avatares/avatar (10).png";
import avatar11 from "../../assets/images/avatares/avatar (11).png";
import avatar12 from "../../assets/images/avatares/avatar (12).png";
import avatar13 from "../../assets/images/avatares/avatar (13).png";
import avatar14 from "../../assets/images/avatares/avatar (14).png";
import avatar15 from "../../assets/images/avatares/avatar (15).png";
import storage from "../../services/storage";
import Avatar from "../../Components/Avatar";
import { validaDataNascimento } from "../../services/validaDataNascimento";

export default function MeuCadastro() {
  const { load, setLoad } = useLoad();
  const [validacao, setValidacao] = useState(false);
  const [listaEstado, setListaEstado] = useState([]);
  const [mesmoCep, setMesmoCep] = useState("");
  const [idCidadeTemporario, setIdCidadeTemporario] = useState("");
  const [listaCargo, setListaCargo] = useState([]);
  const [listaCidade, setListaCidade] = useState([]);
  const [eu, setEu] = useState([]);
  const [trava, setTrava] = useState(false);
  const avatarMapping = {
    1: avatar1,
    2: avatar2,
    3: avatar3,
    4: avatar4,
    5: avatar5,
    6: avatar6,
    7: avatar7,
    8: avatar8,
    9: avatar9,
    10: avatar10,
    11: avatar11,
    12: avatar12,
    13: avatar13,
    14: avatar14,
    15: avatar15,
  };

  const userAvatar = storage.get("USUARIO")?.avatar;
  const selectedAvatar = avatarMapping[userAvatar];

  const [meuCadastro, setMeuCadastro] = useState({
    nome: "",
    cpf: "",
    data: "",
    celular: "",
    email: "",
    empresa: "",
    cargo: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    estado: "",
    cidade: "",
    imgPerfil:
      "https://ovicio.com.br/wp-content/uploads/2021/01/20210125-mnstr_106eh_l-900x0-800x400-1-730x365.jpg",
    senha: "",
    confirmarSenha: "",
  });
  const navigate = useNavigate();

  async function fnCep(id) {
    try {
      setLoad(true);
      let response = await ApiServices.apiGetEndereco("cep/" + id);
      if (response.uf === null) {
        toast.warn("CEP não encontrado");
      }
      setMeuCadastro((prev) => ({
        ...prev,
        estado: response?.uf?.ufId,
        logradouro: response?.logradouro,
        bairro: response?.bairro,
        numero: mesmoCep === id ? meuCadastro.numero : "",
        complemento: mesmoCep === id ? meuCadastro.complemento : "",
      }));
      setIdCidadeTemporario(response?.cidade?.cidadeId);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnEstados() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGetEndereco("uf");
      setListaEstado(response);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCidade(id) {
    try {
      setLoad(true);
      let response = await ApiServices.apiGetEndereco("/cidade/" + id);
      setListaCidade(response);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const fnCarregarImagem = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target.result;
        setMeuCadastro((prev) => ({
          ...prev,
          imgPerfil: base64Image,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  async function fnListaCargo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("role/deslogado/participante");
      setListaCargo(response?.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnUsuario() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");
      setMeuCadastro((prev) => ({
        ...prev,
        nome: response.data?.nome || "",
        cpf: response.data?.userName || "",
        data: moment(response.data?.dataNascimento).format("DD/MM/YYYY") || "",
        celular: response.data?.phoneNumber || "",
        email: response.data?.email || "",
        empresa: response.data?.dealer.razaoSocial || "",
        cargo: response.data?.role?.roleId || "",
        cep: response.data?.cep1 || "",
        logradouro: response.data?.logradouro1 || "",
        complemento: response.data?.complemento1 || "",
        numero: response.data?.numero1 || "",
        bairro: response.data?.bairro1 || "",
        estado: response.data?.uf1?.ufId || "",
        cidade: response.data?.cidade1?.cidadeId || "",
      }));
      setEu(response.data);
      setMesmoCep(response.data?.cep1);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnSalvar() {
    if (!validaDataNascimento(meuCadastro?.data?.replaceAll("/", ""))) {
      toast.error("Data de nascimento inválida ");
      return;
    }
    setTrava(true);
    try {
      setLoad(true);
      var obj = {
        cep1: meuCadastro.cep,
        cidade1: {
          cidadeId: meuCadastro.cidade,
        },
        bairro1: meuCadastro.bairro,
        logradouro1: meuCadastro.logradouro,
        numero1: meuCadastro.numero,
        complemento1: meuCadastro.complemento,
        mesmoEnderecoEntrega: true,
        cep2: null,
        cidade2: null,
        bairro2: null,
        logradouro2: null,
        numero2: null,
        complemento2: null,
        nome: meuCadastro.nome,
        senha: meuCadastro.senha,
        dataNascimento: moment(meuCadastro.data, "DDMMYYYY").format(
          "YYYY-MM-DD"
        ),
        // email: meuCadastro.email,
        // phoneNumber: meuCadastro.celular,
        aceiteRegulamento: true,
      };
      const response = await ApiServices.apiPut("eu", obj);
      const data = await response.json();
      if (data.statusCode === 200) {
        navigate("/home");
        toast.success("Cadastro alterado com sucesso");
      } else {
        toast.error(data.message);
      }
      setTrava(false);
    } catch (err) {
      console.error("err", err);
      setTrava(false);
    } finally {
      setLoad(false);
    }
  }

  function obterLetras(palavras) {
    // Divide a string em palavras com base nos espaços em branco
    const partes = palavras.split(" ");

    if (partes.length >= 1) {
      const primeiraPalavra = partes[0];
      const primeiraLetra = primeiraPalavra[0];

      if (partes.length >= 2) {
        const segundaPalavra = partes[1];
        return primeiraLetra + segundaPalavra[0];
      } else {
        return primeiraPalavra.slice(0, 2);
      }
    }
    return null; // Retorna nulo se a string estiver vazia
  }

  useEffect(() => {
    if (idCidadeTemporario) {
      setMeuCadastro((prev) => ({
        ...prev,
        cidade: idCidadeTemporario,
      }));
    }
  }, [listaCidade, idCidadeTemporario]);

  useEffect(() => {
    fnUsuario();
    fnListaCargo();
  }, []);
  useEffect(() => {
    if (meuCadastro.estado) {
      fnCidade(meuCadastro.estado);
    }
  }, [meuCadastro.estado]);

  useEffect(() => {
    if (meuCadastro.cep.length === 8) {
      fnCep(meuCadastro.cep);
    }
  }, [meuCadastro.cep]);

  useEffect(() => {
    if (listaEstado) {
      fnEstados();
    }
  }, []);

  return (
    <>
      <div className="imgBackground"> </div>
      <Menu />

      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link className="imgVoltarLogado" to={"/home"}>
                <img src={voltar} alt="" />
              </Link>
            </div>
          </div>
          <div className="tituloLogado">Meu cadastro</div>
        </div>

        <br />
      </div>
      <div className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-start w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-3">
            <div className="d-flex justify-content-center">
              <div
                className="divAvatarMeuCadastro pointer"
                onClick={() => {
                  !meuCadastro.cep &&
                    toast.warn("Preencha seus dados para alterar o avatar.");
                }}
              >
                <Avatar
                  nome={meuCadastro.nome}
                  rota={
                    !meuCadastro.cep ? "/meu-cadastro" : "/meu-cadastro/avatar"
                  }
                />
              </div>
            </div>
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={fnCarregarImagem}
            />

            <div className="meuCadastroTexto1">
              <div style={{ marginBottom: 10 }}>Dados pessoais</div>
            </div>

            <label
              htmlFor="labelNomeMeuCadastro"
              className="labelNomeMeuCadastro"
            >
              Nome
            </label>
            <form autoComplete="off">
              <input
                disabled
                autoComplete="off"
                name="labelNomeMeuCadastro"
                className="inputPrimary"
                type="text"
                value={meuCadastro.nome}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    nome: e.target.value,
                  }));
                }}
              />
            </form>

            <label
              htmlFor="labelCpfMeuCadastro"
              className="labelCpfMeuCadastro"
            >
              CPF
            </label>
            <form autoComplete="off">
              <input
                disabled
                autoComplete="off"
                name="labelCpfMeuCadastro"
                className="inputPrimary"
                placeholder="000.000.000-00"
                type="text"
                value={Mask.cpf(meuCadastro.cpf)}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    cpf: Mask.unMask(e.target.value),
                  }));
                }}
              />
            </form>

            <label
              htmlFor="labelCelularMeuCadastro"
              className="labelCelularMeuCadastro"
            >
              Celular
            </label>
            <form autoComplete="off">
              <input
                disabled
                autoComplete="off"
                name="labelCelularMeuCadastro"
                className="inputPrimary"
                placeholder="(00) 00000-0000"
                type="text"
                value={Mask.cel(meuCadastro.celular)}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    celular: Mask.unMask(e.target.value),
                  }));
                }}
              />
            </form>
            <label
              htmlFor="labelEmailMeuCadastro"
              className="labelEmailMeuCadastro"
            >
              E-mail
            </label>
            <form autoComplete="off">
              <input
                disabled
                autoComplete="off"
                name="labelEmailMeuCadastro"
                className="inputPrimary"
                type="text"
                value={meuCadastro.email}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </form>

            <label
              htmlFor="labelEmpresaMeuCadastro"
              className="labelEmpresaMeuCadastro"
            >
              Empresa
            </label>
            <form autoComplete="off">
              <input
                disabled
                autoComplete="off"
                name="labelEmpresaMeuCadastro"
                className="inputPrimary"
                type="text"
                value={meuCadastro.empresa}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    empresa: e.target.value,
                  }));
                }}
              />
            </form>

            <form autoComplete="off">
              <label
                htmlFor="labelCargoMeuCadastro"
                className="labelCargoMeuCadastro"
              >
                Cargo
              </label>

              <select
                disabled
                className="inputPrimary"
                value={meuCadastro.cargo}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    cargo: e.target.value,
                  }));
                }}
              >
                <option value="0">Selecione</option>
                {listaCargo.map((e, i) => (
                  <option key={e.roleId} value={e.roleId}>
                    {e.displayName}
                  </option>
                ))}
              </select>
            </form>
            <label
              htmlFor="labelDataMeuCadastro"
              className="labelDataMeuCadastro"
            >
              Data nascimento
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelDataMeuCadastro"
                className="inputPrimary"
                placeholder="00/00/0000"
                type="text"
                value={Mask.date(meuCadastro.data)}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    data: Mask.unMask(e.target.value),
                  }));
                }}
              />
            </form>
            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            <div className="meuCadastroTexto1">
              <div style={{ marginTop: 30 }}>Endereço para entrega</div>
            </div>
            <label
              htmlFor="labelCepMeuCadastro"
              className="labelCepMeuCadastro"
            >
              CEP
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelCepMeuCadastro"
                className="inputPrimary"
                type="text"
                placeholder="00000-000"
                value={Mask.cep(meuCadastro.cep) || ""}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    cep: Mask.unMask(e.target.value),
                  }));
                }}
              />
            </form>
            <label
              htmlFor="labelLogradouroMeuCadastro"
              className="labelLogradouroMeuCadastro"
            >
              Logradouro
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelLogradouroMeuCadastro"
                className="inputPrimary"
                type="text"
                value={meuCadastro.logradouro || ""}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    logradouro: e.target.value,
                  }));
                }}
              />
            </form>
            <label
              htmlFor="labelNumeroMeuCadastro"
              className="labelNumeroMeuCadastro"
            >
              Número
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelNumeroMeuCadastro"
                className="inputPrimary"
                type="text"
                value={meuCadastro.numero || ""}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    numero: e.target.value,
                  }));
                }}
              />
            </form>
            <label
              htmlFor="labelComplementoMeuCadastro"
              className="labelComplementoMeuCadastro"
            >
              Complemento
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelComplementoMeuCadastro"
                className="inputPrimary"
                type="text"
                value={meuCadastro.complemento || ""}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    complemento: e.target.value,
                  }));
                }}
              />
            </form>
            <label
              htmlFor="labelBairroMeuCadastro"
              className="labelBairroMeuCadastro"
            >
              Bairro
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelBairroMeuCadastro"
                className="inputPrimary"
                type="text"
                value={meuCadastro.bairro || ""}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    bairro: e.target.value,
                  }));
                }}
              />
            </form>

            <form autoComplete="off">
              <label
                htmlFor="labelEstadoMeuCadastro"
                className="labelEstadoMeuCadastro"
              >
                Estado
              </label>

              <select
                name=""
                id=""
                className="inputPrimary"
                value={meuCadastro.estado || "0"}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    estado: e.target.value,
                  }));
                }}
              >
                <option value="0">Selecione</option>
                {listaEstado?.map((e, i) => (
                  <option key={e.ufId} value={e.ufId}>
                    {e.sigla}
                  </option>
                ))}{" "}
              </select>
            </form>

            <form autoComplete="off">
              <label
                htmlFor="labelCidadeMeuCadastro"
                className="labelCidadeMeuCadastro"
              >
                Cidade
              </label>

              <select
                name=""
                id=""
                className="inputPrimary"
                value={meuCadastro?.cidade || "0"}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    cidade: e.target.value,
                  }));
                }}
              >
                <option value="0">Selecione</option>
                {listaCidade?.map((e, i) => (
                  <option key={e.cidadeId} value={e.cidadeId}>
                    {e.nome}
                  </option>
                ))}
              </select>
            </form>

            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            {/* ///////////////////////////////////////////////////////////////////// */}
            <div className="meuCadastroTexto1">
              <div style={{ marginTop: 30 }}>Configurações de acesso</div>
            </div>
            <label
              htmlFor="labelSenhaMeuCadastro"
              className="labelSenhaMeuCadastro"
            >
              Senha
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelSenhaMeuCadastro"
                className="inputPrimary"
                type="password"
                value={meuCadastro.senha}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    senha: e.target.value,
                  }));
                }}
              />
            </form>

            <label
              htmlFor="labelConfirmarSenhaMeuCadastro"
              className="labelConfirmarSenhaMeuCadastro"
            >
              Confirmar senha
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelConfirmarSenhaMeuCadastro"
                className="inputPrimary"
                type="password"
                value={meuCadastro.confirmarSenha}
                onChange={(e) => {
                  setMeuCadastro((prev) => ({
                    ...prev,
                    confirmarSenha: e.target.value,
                  }));
                }}
              />
            </form>
            <br />
            <ForcaSenha
              senha={meuCadastro.senha}
              confirmarSenha={meuCadastro.confirmarSenha}
              setValidacao={setValidacao}
            />
            <button
              disabled={trava}
              onClick={() => {
                fnSalvar();
              }}
              className="btn btn-primary w-100 "
              style={{ margin: "40px 0" }}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
