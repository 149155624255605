import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import { toast } from "react-toastify";

export default function CelularCadastro() {
  const { load, setLoad } = useLoad();
  const [celular, setCelular] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [trava, setTrava] = useState(false);
  async function fnEnviarSms() {
    setTrava(true);
    var obj = {
      userName: storage.get("USUARIO")?.userName,
      email: storage.get("USUARIO")?.email,
      celular: celular,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "duplo-fator/deslogado/enviar/sms",
        obj
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        const atualizado = Object.assign(obj, data.data);

        storage.set("USUARIO", atualizado);

        navigate("/celular-verificacao");
      } else {
        toast.error(data.message);
      }
      setTrava(false);
    } catch (err) {
      console.error("err", err);
      setTrava(false);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground"></div>
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 500 }}>
          <div className="px-5">
            <Link to={"/cpf-cadastro"}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="celularCadastroTexto1">Muito obrigado!</div>
            <div className="celularCadastroTexto2">
              <div style={{ marginBottom: 10 }}>
                Identificamos seu pré-cadastro.
              </div>
              <div style={{ marginBottom: 10 }}>
                Precisamos agora confirmar alguns dados, tudo bem?
              </div>
            </div>
            <label
              htmlFor="labelCelularCadastroCpf"
              className="labelCelularCadastroCpf"
            >
              Celular
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelCelularCadastroCpf"
                className="inputPrimary"
                placeholder="(00) 00000-0000"
                maxLength={15}
                inputMode="numeric"
                type="text"
                value={Mask.cel(celular)}
                onChange={(e) => {
                  setCelular(Mask.unMask(e.target.value));
                }}
              />
            </form>
            <div className="celularCadastroTexto3">
              Vamos enviar por SMS um código de validação para seu número de
              telefone
            </div>

            <button
              onClick={fnEnviarSms}
              disabled={celular.length < 11 || trava}
              className="btn btn-primary w-100 "
              style={{ marginTop: 35 }}
            >
              Enviar código SMS
            </button>
            <div className="celularCadastroTexto4">
              Se não reconhece esse número, entre em contato com seu gerente
              para maiores informações.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
