import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";

export default function Regulamento() {
  const { load, setLoad } = useLoad();
  const [regulamento, setRegulamento] = useState({
    titulo: "",
    status: "ativo",
    mensagem: ""
  });

  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const fnCarregarRegulamento = async () => {
    try {
      let response = await ApiServices.apiGet('regulamento/ativo');

      if (response && response.data) {
        setRegulamento((prev) => ({
          ...prev,
          titulo: response.data.titulo,
          mensagem: response.data.mensagem
        }));
      } else {
        console.error('Dados de resposta inesperados:', response);
      }
    } catch (error) {
      console.error('Erro ao carregar regulamento:', error);
    }
  };

  useEffect(() => {
    fnCarregarRegulamento();
  }, []);

  return (
    <>
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link onClick={() => window.history.back()}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Termos e condições</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-3">
            <div className="regulamentoTexto1"
              dangerouslySetInnerHTML={{ __html: regulamento.titulo }}>
            </div>

            <div className="regulamentoTexto2"
              dangerouslySetInnerHTML={{ __html: regulamento.mensagem }}>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
