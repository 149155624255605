import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import { validaCpf } from "../../services/validaCpf";
import { toast } from "react-toastify";
import storage from "../../services/storage";

export default function CpfCadastro() {
  const { load, setLoad } = useLoad();
  const [cpf, setCpf] = useState("");
  const navigate = useNavigate();
  const [trava, setTrava] = useState(false);

  async function fnCadastrarCpf() {
    setTrava(true);
    try {
      setLoad(true);
      var obj = {
        userName: cpf,
      };
      await ApiServices.apiPost("usuario/existe", obj)
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            navigate("/email-cadastro");
            storage.set("USUARIO", { email: res.data.email, userName: cpf });
          } else {
            toast.error(res.message);
          }
        });
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground"></div>
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 500 }}>
          <div className="px-5">
            <Link to={"/"}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="cadastroTexto1">Vamos lá</div>
            <div className="cadastroTexto2">Comece informando seu CPF.</div>
            <label htmlFor="labelCadastroCpf" className="labelCadastroCpf">
              CPF
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelCadastroCpf"
                className="inputPrimary"
                placeholder="000.000.000-00"
                type="text"
                maxLength={14}
                inputMode="numeric"
                value={Mask.cpf(cpf)}
                onChange={(e) => {
                  setCpf(Mask.unMask(e.target.value));
                }}
              />
              <div
                className="labelLoginCpf m-0"
                style={{ color: "#FF3E0E", fontWeight: 600 }}
              >
                {validaCpf(cpf) || cpf.length < 11 ? " " : "CPF invalido"}
              </div>
            </form>

            <button
              onClick={fnCadastrarCpf}
              className="btn btn-primary w-100 "
              disabled={!validaCpf(cpf) || cpf.length != 11 || trava}
              style={{ marginTop: 210 }}
            >
              Próximo passo
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
