import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Footer from "../Pages/Footer"; 
import storage from "../services/storage";
import Login from "../Pages/Login";
import EsqueceuSenha from "../Pages/EsqueceuSenha";
import EsqueceuSenhaConfirmacao from "../Pages/EsqueceuSenhaConfirmacao"; 
import CelularVerificado from "../Pages/CelularVerificado";
import CelularVerificacao from "../Pages/CelularVerificacao";
import CelularCadastro from "../Pages/CelularCadastro";
import EmailCadastro from "../Pages/EmailCadastro";
import EmailVerificacao from "../Pages/EmailVerificacao";
import EmailVerificado from "../Pages/EmailVerificado";
import Sobre from "../Pages/Sobre";
import Senha from "../Pages/Senha";
import Termo from "../Pages/Termo";
import Finalizado from "../Pages/Finalizado";
import MeuCadastro from "../Pages/MeuCadastro";  
import Regulamento from "../Pages/Regulamento"; 
import Home from "../Pages/Home";
import RecriarSenha from "../Pages/RecriarSenha"; 
import CpfCadastro from "../Pages/CpfCadastro";
import Catalogo from "../Pages/Catalogo"; 
import MeuCadastroAvatar from "../Pages/MeuCadastroAvatar";
import NoticiaDetalhe from "../Pages/NoticiaDetalhe";
import CampanhaDetalhe from "../Pages/CampanhaDetalhe"; 
import EmailRecadastroLogin from "../Pages/EmailRecadastroLogin";
import EmailRecadastroEmail from "../Pages/EmailRecadastroEmail";
import EmailRecadastroVerificacao from "../Pages/EmailRecadastroVerificacao";
import EmailRecadastroVerificado from "../Pages/EmailRecadastroVerificado";
import PerguntasFrequentes from "../Pages/PerguntasFrequentes";
import FaleConosco from "../Pages/FaleConosco";
import FaleConoscoDetalhe from "../Pages/FaleConoscoDetalhe";
import Campanhas from "../Pages/Campanhas";
import Noticias from "../Pages/Noticias";
import EsqueceuSenhaConfirmacaoSucesso from "../Pages/EsqueceuSenhaConfirmacaoSucesso";
import RankingCompleto from "../Pages/RankingCompleto";
import Performance from "../Pages/Performance";
import ApiServices from "../services/apiServices";
import ComoFunciona from "../Pages/ComoFunciona";
import PDF from "../Components/Pdf";
 


export default function Rotas() {
  const navigate = useNavigate()

  const PrivateRoutes = ({ children, redirectTo }) => {
    var autenticado = storage.get("USUARIO")?.token != null; 
    fnEu()
    return autenticado ? (
      <>
          <div style={{marginBottom:120}}>{children}</div>    
             <Footer/>        
      </>
    ) : (
      <Navigate to={redirectTo} />
    );
  };

  async function fnEu() {
    try {
      let response = await ApiServices.apiGet("eu"); 
   
    } catch (err) {
      navigate('/')
    } finally {
    }
  }
 
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/esqueci-minha-senha" element={<EsqueceuSenha />} />
        <Route exact path="/esqueci-minha-senha/confirmacao"element={<EsqueceuSenhaConfirmacao />}/>
        <Route exact path="/esqueci-minha-senha/confirmacao/sucesso"element={<EsqueceuSenhaConfirmacaoSucesso />}/>
        <Route exact path="/cpf-cadastro" element={<CpfCadastro/>} />

        <Route exact path="/celular-cadastro" element={<CelularCadastro />} />
        <Route exact path="/celular-verificacao" element={<CelularVerificacao />} />
        <Route exact path="/celular-verificado" element={<CelularVerificado />} />

        <Route exact path="/email-cadastro" element={<EmailCadastro/>} />
        <Route exact path="/email-verificacao" element={<EmailVerificacao/>} />
        <Route exact path="/email-verificado" element={<EmailVerificado/>} />
        <Route exact path="/email-recadastro-login" element={<EmailRecadastroLogin/>} />        
        <Route exact path="/email-recadastro-email" element={<EmailRecadastroEmail/>} />
        <Route exact path="/email-recadastro-verificacao" element={<EmailRecadastroVerificacao/>} />
        <Route exact path="/email-recadastro-verificado" element={<EmailRecadastroVerificado/>} />
        
        <Route exact path="/alterar-senha" element={<RecriarSenha/>}/> 
        <Route exact path="/sobre" element={<Sobre/>} />
        <Route exact path="/senha" element={<Senha/>} />
        <Route exact path="/termo" element={<Termo/>} />
        <Route exact path="/finalizado" element={<Finalizado/>} />

        <Route exact path="/meu-cadastro" element={<PrivateRoutes redirectTo={"/"}> <MeuCadastro/> </PrivateRoutes> }/> 
        <Route exact path="/meu-cadastro/avatar" element={<PrivateRoutes redirectTo={"/"}> <MeuCadastroAvatar/> </PrivateRoutes> }/> 
        <Route exact path="/campanhas" element={<PrivateRoutes redirectTo={"/"}> <Campanhas/> </PrivateRoutes> }/> 
        <Route exact path="/campanha-detalhe/:id" element={<PrivateRoutes redirectTo={"/"}> <CampanhaDetalhe/> </PrivateRoutes> }/> 
        <Route exact path="/regulamento" element={<PrivateRoutes redirectTo={"/"}> <Regulamento/> </PrivateRoutes> }/>  
        <Route exact path="/noticia-detalhe/:id" element={<PrivateRoutes redirectTo={"/"}> <NoticiaDetalhe/> </PrivateRoutes> }/> 
        <Route exact path="/home" element={<PrivateRoutes redirectTo={"/"}> <Home/> </PrivateRoutes> }/> 
        <Route exact path="/:id" element={<PrivateRoutes redirectTo={"/"}> <Home/> </PrivateRoutes> }/> 
        <Route exact path="/catalogo" element={<PrivateRoutes redirectTo={"/"}> <Catalogo/> </PrivateRoutes> }/> 
        <Route exact path="/como-funciona" element={<PrivateRoutes redirectTo={"/"}> <ComoFunciona/> </PrivateRoutes> }/> 
        <Route exact path="/como-funciona/detalhe" element={ <PDF/>}/> 

        <Route exact path="/noticia" element={<PrivateRoutes redirectTo={"/"}> <Noticias/> </PrivateRoutes> }/> 
        <Route exact path="/noticia-detalhe" element={<PrivateRoutes redirectTo={"/"}> <NoticiaDetalhe/> </PrivateRoutes> }/> 
       
        <Route exact path="/perguntas-frequentes" element={<PrivateRoutes redirectTo={"/"}> <PerguntasFrequentes/> </PrivateRoutes> }/>
        <Route exact path="/fale-conosco" element={<PrivateRoutes redirectTo={"/"}> <FaleConosco/> </PrivateRoutes> }/> 
        <Route exact path="/fale-conosco-historico" element={<PrivateRoutes redirectTo={"/"}> <FaleConoscoDetalhe/> </PrivateRoutes> }/> 
        <Route exact path="/ranking-completo" element={<PrivateRoutes redirectTo={"/"}> <RankingCompleto/> </PrivateRoutes> }/> 
        <Route exact path="/performance" element={<PrivateRoutes redirectTo={"/"}> <Performance/> </PrivateRoutes> }/> 
      </Routes>
      
    </div>
  );
}
