import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";

export default function Termo() {
  const { load, setLoad } = useLoad();
  const [regulamento, setRegulamento] = useState({
    titulo: "",
    status: "ativo",
    mensagem: ""
  });

  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const fnCarregarRegulamento = async () => {
    try {
      let response = await ApiServices.apiGet('regulamento/ativo');

      if (response && response.data) {
        setRegulamento((prev) => ({
          ...prev,
          titulo: response.data.titulo,
          mensagem: response.data.mensagem
        }));
      } else {
        console.error('Dados de resposta inesperados:', response);
      }
    } catch (error) {
      console.error('Erro ao carregar regulamento:', error);
    }
  };

  useEffect(() => {
    fnCarregarRegulamento();
  }, []);

  return (
    <>
      <div className="imgBackground"></div>
      <div className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-start w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-5">
            <Link to={"/senha"}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="termoTexto1">Termos e condições</div>
            <div className="termoTexto2"
              dangerouslySetInnerHTML={{ __html: regulamento.titulo }}>
            </div>
            <div className="termoTexto3"
              dangerouslySetInnerHTML={{ __html: regulamento.mensagem }}>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
