import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load"; 
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png"; 
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu"; 
import smileUser from "../../assets/images/svg/smileUser.svg";
import smileFeliz from "../../assets/images/svg/smileFeliz.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export default function RankingCompleto() {
  const { load, setLoad } = useLoad(); 
  const [verMais, setVerMais] = useState(10);
  const [verModal, setVerModal] = useState({});
  const [listaRankingPorGrupo, setListaRankingPorGrupo] = useState([]);  
  const [botaoGrupos, setBotaoGrupos] = useState([]);
  const [botaoSelecionado, setBotaoSelecionado] = useState(0);
  const [participanteGrupoId, setParticipanteGrupoId] = useState("");

  async function fnListaRankingPorGrupo() {
    try {
      const response = await ApiServices.apiGet(
        "ranking/meu?participanteGrupoId=" +
          participanteGrupoId +
          "&page=1&pageSize=" +
          verMais
      );

      setListaRankingPorGrupo(response.data);

      // fnPerformanceUltimaPosicaoVista(id);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPerformance() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("performance/meu-resumo");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnRankingResumo() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("ranking/meu-resumo");

      setBotaoGrupos(response.data);
      if (participanteGrupoId == "") {
        setParticipanteGrupoId(response?.data[0]?.participanteGrupoId);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPerformanceUltimaPosicaoVista(id) {
    var objEnvio = { participanteGrupoId: id };

    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "ranking/meu/marcar-como-visualizado",
        objEnvio
      );
      fnRankingResumo();
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnRankingResumo();
    fnPerformance();
  }, []);

  useEffect(() => {
    if (participanteGrupoId) {
      fnListaRankingPorGrupo();
    }
  }, [participanteGrupoId, verMais]);
  useEffect(() => {
    botaoGrupos.map((e, i) => {
      if (
        e.participanteGrupoId === participanteGrupoId &&
        e.posicao !== e.posicaoUltimaVisita
      ) {
        setVerModal(e);
      }
    });
  }, [participanteGrupoId]);

  return (
    <div className="w-100 ">
      <div className="imgBackground"> </div>
      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/home"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Ranking Geral</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      {/* BOTAO GRUPO A | GRUPO B */}
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="d-flex justify-content-around w-100 my-5">
            {botaoGrupos.map((e, i) => (
              <div key={i}>
                <button
                  style={{ width: 200 }}
                  className={
                    "btn " +
                    (botaoSelecionado === i
                      ? "btn-primary "
                      : " btn-secondary  ")
                  }
                  onClick={() => {
                    setBotaoSelecionado(i);
                    setParticipanteGrupoId(e.participanteGrupoId);
                    setVerMais(10);
                  }}
                >
                  {e.participanteGrupoNome}
                </button>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center px-3">
            <div
              className="d-flex flex-column justify-content-start w-100"
              style={{ maxWidth: 500 }}
            >
              {(listaRankingPorGrupo.items || []).map(
                (e, i) =>
                  i < verMais && (
                    <div
                      className={
                        "cardRankingCompleto " +
                        (e.souEu ? "rankingEuSelecionado" : "")
                      }
                    >
                      <div className="posicaoRankingCompleto mx-2 mt-1">
                        {e.posicao}°
                      </div>
                      {e.participanteUrlAvatar ? (
                        <img
                          className="mx-2"
                          style={{ borderRadius: "50%" }}
                          width={20}
                          src={e.participanteUrlAvatar}
                          alt=""
                        />
                      ) : e.souEu ? (
                        <img
                          className="mx-2"
                          width={20}
                          src={smileFeliz}
                          alt=""
                        />
                      ) : (
                        <img
                          className="mx-2"
                          width={20}
                          src={smileUser}
                          alt=""
                        />
                      )}
                      <div className="nomeRankingCompleto mx-2">
                        {e.participanteNome}{" "}
                      </div>
                      <div className="pontosRankingCompleto mx-2">
                        Pontos{" "}
                        {e.pontos?.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
          {(listaRankingPorGrupo.items || []).length >= verMais && (
            <div className="d-flex justify-content-center mt-4">
              <button
                style={{ minWidth: 200 }}
                className="btn btn-secondary w-100"
                onClick={() => {
                  setVerMais(verMais + 10);
                }}
              >
                Ver mais
              </button>
            </div>
          )}
        </div>
      </div>
      {verModal.participanteGrupoId && (
        <div className="modalExterno">
          <div className="modalInterno">
            <div className="tituloModal">Parabéns!</div>
            <div className="textoModal">
              Você 
              {verModal.posicao - verModal.posicaoUltimaVisita > 0
                ? "subiu " + (verModal.posicao - verModal.posicaoUltimaVisita)
                : "desceu " + (verModal.posicao - verModal.posicaoUltimaVisita)}
               posições desde sua última visita!
              <br />
              <br />
              Você está a <b>{verModal.pontosFaltamPraProximaPosicao} </b>
              pontos da próxima posição. Boas vendas!
              <br />
              <br />
              Boas vendas!
              <br />
              <br />
              <button
                className="btn btn-secondary"
                onClick={() => {
                  fnPerformanceUltimaPosicaoVista(verModal.participanteGrupoId);
                  setVerModal({});
                }}
              >
                Ver ranking
              </button>
              <br />
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
}
