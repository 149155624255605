import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate, useParams } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanhaImg from "../../assets/images/png/campanha.png";
import semImagem from "../../assets/images/png/360x360.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";

export default function CampanhaDetalhe() {
  const { load, setLoad } = useLoad();
  const [campanha, setCampanha] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  async function fnCampanha() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("campanha/" + params.id);
      setCampanha(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnCampanha();
  }, []);

  return (
    <>
      <div className="imgBackground"></div>
      <Menu />

      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
            </div>
          </div>
          <div className="tituloLogado">Campanha</div>
        </div>
        <br />
      </div>

      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div className="d-flex justify-content-center w-100" style={{ maxWidth: 500 }}>
          <div className="px-3">
            <div className="campanhaDetalheTexto1">{campanha.nome}</div>
            <div className="d-flex justify-content-center ">
              <img
                className="imgCampanhaDetalhe"
                src={
                  campanha &&
                  campanha.urlImagem &&
                  campanha.urlImagem.includes("blob")
                    ? campanha.urlImagem
                    : semImagem
                }
                alt=""
              />
            </div>
            <div className="campanhaDetalheTexto2">Como funciona</div>
            {/* <div className="campanhaDetalheTexto3">{campanha?.tipo?.nome}</div>
        <div className="campanhaDetalheTexto4">
        {moment(campanha?.dataInicio).format("DD/MM/YYYY")}
      </div> */}
            <div className="campanhaDetalheTexto5">
              {/* {campanha.descricao} */}
              <br />
              {campanha.comoFunciona}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
