const _set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
  return _get(key);
};

const _get = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const _del = (key) => {
  localStorage.removeItem(key);
};

const _getUser = () => {
  let store = JSON.parse(localStorage.getItem(`DEVP_USER_INFOS`));
  return store;
};
const _getUserRoleta = () => {
  let store = JSON.parse(localStorage.getItem(`DEVP_USER_INFOS_ROLETA`));
  return store;
};

export default {
  set: _set,
  get: _get,
  del: _del,
  getUser: _getUser,
  getUserRoleta: _getUserRoleta,
};
