import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import { validaDataNascimento } from "../../services/validaDataNascimento";

export default function Sobre() {
  const { load, setLoad } = useLoad();
  const [listaCargo, setListaCargo] = useState([]);
  const [sobre, setSobre] = useState({
    nome: storage.get("USUARIO")?.nome,
    data: "",
    roleId: storage.get("USUARIO")?.cargoId,
  });
  const navigate = useNavigate();

  async function fnListaCargo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("role/deslogado/participante");
      setListaCargo(response?.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListaCargo();
  }, []);
  return (
    <>
      <div className="imgBackground"></div>
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 500 }}>
          <div className="px-5">
            <Link to={"/celular-cadastro"}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="sobreTexto1">Muito obrigado!</div>
            <div className="sobreTexto2">
              <div style={{ marginBottom: 10 }}>
                Agora precisamos saber um pouco mais sobre você!
              </div>
            </div>
            <label htmlFor="labelSobreNome" className="labelSobreNome">
              Nome completo
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                disabled
                name="labelSobreNome"
                className="inputPrimary"
                type="text"
                value={sobre?.nome}
                onChange={(e) => {
                  setSobre((prev) => ({
                    ...prev,
                    nome: e.target.value,
                  }));
                }}
              />
            </form>
            <label htmlFor="labelSobreCargo" className="labelSobreCargo">
              Cargo
            </label>

            <select
              value={sobre?.roleId}
              onChange={(e) => {
                setSobre((prev) => ({ ...prev, roleId: e.target.value }));
              }}
              className="inputPrimary"
            >
              <option value="">Selecione</option>
              {listaCargo?.map((e, i) => (
                <option key={e.roleId} value={e.roleId}>
                  {e.displayName}
                </option>
              ))}
            </select>
            <label htmlFor="labelSobreData" className="labelSobreData">
              Data nascimento
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelSobreData"
                className="inputPrimary"
                placeholder="00/00/0000"
                maxLength={10}
                inputMode="numeric"
                type="text"
                value={Mask.date(sobre?.data)}
                onChange={(e) => {
                  setSobre((prev) => ({
                    ...prev,
                    data: Mask.unMask(e.target.value),
                  }));
                }}
              />
              <div className="avisoCampo">
                {!validaDataNascimento(sobre?.data) && sobre?.data.length == 8
                  ? "Data de nascimento inválida"
                  : " "}
              </div>
            </form>
            <button
              disabled={
                sobre?.nome?.length < 3 ||
                sobre?.data?.length != 8 ||
                sobre?.roleId == "" ||
                !validaDataNascimento(sobre?.data) ||
                sobre?.data.length < 8
              }
              onClick={() => {
                const atualizado = Object.assign(storage.get("USUARIO"), sobre);
                storage.set("USUARIO", atualizado);
                navigate("/senha");
              }}
              className="btn btn-primary w-100 "
              style={{ marginTop: 50 }}
            >
              Próximo passo
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
