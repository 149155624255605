import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import avatar1 from "../../assets/images/avatares/avatar (1).png";
import avatar2 from "../../assets/images/avatares/avatar (2).png";
import avatar3 from "../../assets/images/avatares/avatar (3).png";
import avatar4 from "../../assets/images/avatares/avatar (4).png";
import avatar5 from "../../assets/images/avatares/avatar (5).png";
import avatar6 from "../../assets/images/avatares/avatar (6).png";
import avatar7 from "../../assets/images/avatares/avatar (7).png";
import avatar8 from "../../assets/images/avatares/avatar (8).png";
import avatar9 from "../../assets/images/avatares/avatar (9).png";
import avatar10 from "../../assets/images/avatares/avatar (10).png";
import avatar11 from "../../assets/images/avatares/avatar (11).png";
import avatar12 from "../../assets/images/avatares/avatar (12).png";
import avatar13 from "../../assets/images/avatares/avatar (13).png";
import avatar14 from "../../assets/images/avatares/avatar (14).png";
import avatar15 from "../../assets/images/avatares/avatar (15).png";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
export default function Avatar({ nome, rota, font }) {
  const { load, setLoad } = useLoad();
  const [eu, setEu] = useState({});
  const navigate = useNavigate();
  const avatarMapping = {
    1: avatar1,
    2: avatar2,
    3: avatar3,
    4: avatar4,
    5: avatar5,
    6: avatar6,
    7: avatar7,
    8: avatar8,
    9: avatar9,
    10: avatar10,
    11: avatar11,
    12: avatar12,
    13: avatar13,
    14: avatar14,
    15: avatar15,
  };

  const selectedAvatar = avatarMapping[Number(eu.urlAvatar)];

  function obterLetras(palavras) {
    // Divide a string em palavras com base nos espaços em branco
    const partes = palavras.split(" ");

    if (partes.length >= 1) {
      const primeiraPalavra = partes[0];
      const primeiraLetra = primeiraPalavra[0];

      if (partes.length >= 2) {
        const segundaPalavra = partes[1];
        return primeiraLetra + segundaPalavra[0];
      } else {
        return primeiraPalavra.slice(0, 2);
      }
    }
    return null; // Retorna nulo se a string estiver vazia
  }
  async function fnUsuario() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");
      setEu(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnUsuario();
  }, []);

  return (
    <div
      className="divImagemPerfilAvatar"
      onClick={() => {
        navigate(rota);
      }}
    >
      {eu.urlAvatar ? (
        <img
          className="imagemPerfilAvatar"
          src={eu.urlAvatar.includes("blob") ? eu.urlAvatar : selectedAvatar}
          alt=""
        />
      ) : (
        <div className="letraPerfilAvatar" style={{ fontSize: font }}>
          {obterLetras(nome || "")}
        </div>
      )}
    </div>
  );
}
