import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import lupa from "../../assets/images/png/lupa.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
// import Accordion from "react-bootstrap/Accordion";
export default function PerguntasFrequentes() {
  const { load, setLoad } = useLoad();
  const [pesquisa, setPesquisa] = useState("");
  const [listaFaq, setListaFaq] = useState([]);
  const navigate = useNavigate();

  async function fnListaFaq() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("faq/de-participante");
      setListaFaq(response?.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const filteredData = listaFaq?.filter((item) => {
    const pesquisaLowerCase = pesquisa?.toLowerCase();
    return (
      item.titulo.toLowerCase().includes(pesquisaLowerCase) ||
      item.texto.toLowerCase().includes(pesquisaLowerCase)
    );
  });
  useEffect(() => {
    fnListaFaq();
  }, []);
  return (
    <>
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/home"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">FAQ</div>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-3">
            <div className="perguntasFrequentesTexto1">
              Perguntas frequentes
            </div>
          </div>
          <div className="px-3">
            <form autoComplete="off" className="position-relative">
              <input
                style={{ paddingRight: 50 }}
                autoComplete="off"
                placeholder="Pesquisar no FAQ"
                className="inputPrimary"
                type="text"
                value={pesquisa}
                onChange={(e) => {
                  setPesquisa(e.target.value);
                }}
              />
              <img
                className="imgLupaCatalogoPerguntasFrequentes"
                width={20}
                src={lupa}
                alt=""
              />
            </form>
            <div className="accordion" id="accordionExample">
              {(filteredData || []).map((item, i) => (
                <div
                  class="accordion"
                  id="accordionExample"
                  style={{ width: 468 }}
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#itemAccordion" + i}
                        aria-expanded="false"
                        aria-controls={"itemAccordion" + i}
                      >
                        {item.titulo}
                      </button>
                    </h2>
                    <div
                      id={"itemAccordion" + i}
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div dangerouslySetInnerHTML={{ __html: item.texto }} />
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>

                // <div className="accordion-item" key={i}>
                //   <button
                //     className="accordion-button"
                //     type="button"
                //     data-bs-toggle="collapse"
                //     data-bs-target={"#collapse" + i}
                //     aria-expanded={false}
                //     aria-controls={"collapse" + i}
                //   >
                //     {item.titulo}
                //   </button>
                //   <div
                //     id={"collapse" + i}
                //     className="accordion-collapse collapse"
                //     aria-labelledby={"heading" + i}
                //   >
                //     <div className="accordion-body">{item.texto}</div>
                //   </div>
                //   {filteredData.length !== i + 1 && <hr className="m-0" />}
                // </div>
              ))}
            </div>
            {filteredData.length == 0 && (
              <div className="text-center ">
                Não foi possivel encontrar resultado para a pesquisa
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
