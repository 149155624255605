import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import bg from "../../assets/images/png/bg.png";
import logo from "../../assets/images/png/logo.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import { validaCpf } from "../../services/validaCpf";
import { toast } from "react-toastify";
import ForcaSenha from "../../Components/ForcaSenha";
import { Visibility } from "@mui/icons-material";
import Logo2 from "../../assets/images/png/dev2.png";
import Linkedin from "../../assets/images/svg/linkedin.svg";
import Instagram from "../../assets/images/svg/instagram.svg";
import Facebook from "../../assets/images/svg/facebook.svg";
import Youtube from "../../assets/images/svg/youtube.svg";
import PDF from "../../Components/Pdf";

export default function Login() {
  const { load, setLoad } = useLoad();
  const [trava, setTrava] = useState(false);
  const [validacao, setValidacao] = useState(false);
  const [login, setLogin] = useState({
    cpf: "",
    senha: "",
  });
  const navigate = useNavigate();

  async function fnLogin() {
    setTrava(true);
    try {
      storage.del("USUARIO");
      setLoad(true);
      var obj = {
        userName: login.cpf,
        password: login.senha,
      };

      await ApiServices.apiPost("login/portal", obj)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 200) {
            storage.set("USUARIO", data.data);
            navigate("/home");
          } else {
            toast.error(data.message);
          }
        });
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  return (
    <>
      <div style={{ display: "none" }}>
        <ForcaSenha
          senha={login.senha}
          confirmarSenha={login.senha}
          setValidacao={setValidacao}
        />
      </div>
      <div
        className="imgBackground"
        style={{ backgroundImage: `url(${bg})` }}
      ></div>
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 500 }}>
          <div className="px-5">
            <div className="d-flex justify-content-center ">
              <img className="imgLogo" src={logo} alt="" />
            </div>
            <div className="loginTexto1 text-center ">
              Que bom ver você por aqui!
            </div>
            <div className="loginTexto2 ">Insira seu login de acesso </div>
            <label htmlFor="inputLoginCpf" className="labelLoginCpf">
              CPF
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="inputLoginCpf"
                className="inputPrimary"
                placeholder="000.000.000-00"
                inputMode="numeric"
                maxLength={14}
                type="text"
                value={Mask.cpf(login.cpf)}
                onChange={(e) => {
                  setLogin((prev) => ({
                    ...prev,
                    cpf: Mask.unMask(e.target.value),
                  }));
                }}
              />
              <div
                className="labelLoginSenha m-0"
                style={{ color: "#FF3E0E", fontWeight: 600 }}
              >
                {validaCpf(login.cpf) || login.cpf.length < 11
                  ? " "
                  : "CPF invalido"}{" "}
              </div>
            </form>
            <label htmlFor="labelLoginSenha" className="labelLoginSenha">
              Senha
            </label>
            <form autoComplete="off">
              {/* esse input vazio é para enganar o navegar => AUTOCOMPLETE */}
              <input
                type="password"
                style={{ display: "none" }}
                autoComplete="off"
              />
              <input
                autoComplete="off"
                name="labelLoginSenha"
                className="inputPrimary"
                type="password"
                value={login.senha}
                onChange={(e) => {
                  setLogin((prev) => ({ ...prev, senha: e.target.value }));
                }}
              />
            </form>
            <div className="esqueciSenha pointer">
              <Link to={"/esqueci-minha-senha"}>Esqueci minha senha</Link>
            </div>

            <button
              className="btn btn-primary w-100 btnEntrarLogin"
              onClick={fnLogin}
              disabled={
                !validaCpf(login.cpf) ||
                login.cpf.length != 11 ||
                trava ||
                !validacao
              }
            >
              Entrar
            </button>
            <Link to={"/cpf-cadastro"}>
              <button className="btn btn-secondary w-100 btnCadastroLogin">
                Primeira vez aqui? <b>Cadastre-se</b>
              </button>
            </Link>

            <div className=" w-100 footerLogin mt-3">
              <div className="mt-2">
                <div className="d-flex justify-content-center mt-5">
                  <img className="ms-2" width={150} src={Logo2} alt="" />
                </div>
              </div>
              <div className="text-center">
                {/* <img className="m-3 logoRede pointer" src={Twitter} alt="" /> */}
                <a href="https://www.youtube.com/@devpartnerbr" target="_blank">
                  <img className="m-3 logoRede pointer" src={Youtube} alt="" />
                </a>
                <a href="https://www.facebook.com/devpartnerbr" target="_blank">
                  <img className="m-3 logoRede pointer" src={Facebook} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/devpartnerbr/"
                  target="_blank"
                >
                  <img
                    className="m-3 logoRede pointer"
                    src={Instagram}
                    alt=""
                  />
                </a>
                <a
                  href="https://br.linkedin.com/company/devpartnerbr"
                  target="_blank"
                >
                  <img className="m-3 logoRede pointer" src={Linkedin} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
