import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import voltar from "../../assets/images/png/voltar.png";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../../Components/Menu";
import ApiServices from "../../services/apiServices";
import globals from "../../globals";
import setaCima from "../../assets/images/png/setaCima.png";
import storage from "../../services/storage";

export default function Catalogo() {
  const { load, setLoad } = useLoad();
  const [trava, setTrava] = useState(false);
  const CATALOGO = globals.REACT_APP_CATALOGO;
  const [erroCatalogo, setErroCatalogo] = useState(false);
  const navigate = useNavigate();
  const [eu, setEu] = useState({});
  async function fnEu() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");
      setEu(response.data);
      if (
        response.data.cadastroCompleto == true &&
        response.data.cep1.length > 0 &&
        response.data.logradouro1.length > 0 &&
        response.data.cidade1 &&
        response.data.uf1 &&
        response.data.bairro1.length > 0 &&
        response.data.numero1.length > 0
      ) {
        setErroCatalogo(false);
        fnCatalogo();
      } else {
        setErroCatalogo(true);
      }

      console.log(response.data);
    } catch (err) {
      setTrava(false);
      setErroCatalogo(true);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCatalogo() {
    setTrava(true);

    try {
      setLoad(true);

      const response = await ApiServices.apiPost("catalogo", {
        token: storage.get("USUARIO")?.token,
      });
      const data = await response.json();
      window.open(data.data.message, "_self");
      setTrava(false);
    } catch (err) {
      setTrava(false);
      setErroCatalogo(true);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  return (
    <>
      <div className="imgBackground"> </div>
      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link className="imgVoltarLogado" to={"/home"}>
                <img src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Catálogo</div>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-3 mt-5">
            {erroCatalogo ? (
              <button
                className="btn btn-secondary w-100 "
                onClick={() => {
                  navigate("/meu-cadastro");
                }}
              >
                Acessar Meu Cadastro
              </button>
            ) : (
              <button
                onClick={fnEu}
                className="btn btn-primary w-100 "
                disabled={!CATALOGO || trava}
              >
                Acessar catálogo
              </button>
            )}
          </div>

          <div
            className="labelLoginSenha m-0 d-flex flex-column align-items-center "
            style={{
              color: "#fff",
              fontWeight: 600,
              padding: 50,
              textAlign: "center",
            }}
          >
            {erroCatalogo && (
              <img className="mb-4" width={50} src={setaCima} alt="" />
            )}
            {erroCatalogo
              ? "Necessário preencher seu endereço. Após completar seus dados, tente novamente acesso ao catálogo. "
              : " "}
          </div>

          <div
            className="labelLoginSenha m-0"
            style={{
              color: "#FF3E0E",
              fontWeight: 600,
              padding: 50,
              textAlign: "center",
            }}
          >
            {!CATALOGO
              ? "Em ambiente de teste, acesso ao catálogo sempre será desabilitado."
              : " "}
          </div>
        </div>
      </div>
    </>
  );
}
