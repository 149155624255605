import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";

export default function EsqueceuSenhaConfirmacaoSucesso() {
  const { load, setLoad } = useLoad();
  const [cpf, setCpf] = useState("");
  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground"></div>
      <div className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-start w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-5">
            <Link onClick={() => window.history.back()} className="invisible ">
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="esqueciConfirmacaoTexto1">
              E-mail enviado com sucesso!
            </div>
            <div className="esqueciConfirmacaoTexto2">
              Enviamos as instruções para o e-mail cadastrado.
            </div>

            <button
              onClick={() => {
                navigate("/");
              }}
              className="btn btn-secondary w-100"
              style={{ marginTop: 50 }}
            >
              Voltar para a tela inicial
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
