import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import lupa from "../../assets/images/png/lupa.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { toast } from "react-toastify";
import storage from "../../services/storage";
export default function FaleConosco() {
  const { load, setLoad } = useLoad();
  const [trava, setTrava] = useState(false);
  const [listaMotivo, setListaMotivo] = useState([]);
  const [enviado, setEnviado] = useState(false);
  const [motivo, setMotivo] = useState({ motivoId: "", mensagem: "" });
  const navigate = useNavigate();

  async function fnListar() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("faleconosco/assunto");
      setListaMotivo(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnEnviar() {
    setTrava(true);
    setLoad(true);
    try {
      var objEnvio = {
        anexo:
          "https://triunfo.pe.gov.br/pm_tr430/wp-content/uploads/2018/03/sem-foto.jpg",
        participanteId: storage.get("USUARIO").userId,
        nome: storage.get("USUARIO").nome,
        faleConoscoAssuntoId: motivo.motivoId,
        mensagem: motivo.mensagem,
        anexo: "",
      };
      let response = await ApiServices.apiPost(
        "faleconosco/criar-como-participante",
        objEnvio
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success("Enviado com sucesso");
        setEnviado(true);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setTrava(false);
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListar();
  }, []);

  return (
    <>
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/home"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Fale conosco</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          {!enviado ? (
            <div className="px-3">
              <div className="faleConoscoTexto1">
                Mande sua dúvida pra gente!
              </div>
              <div className="faleConoscoTexto2">
                Se você já leu as perguntas frequentes e ainda não encontrou uma
                solução, entre em contato e ficaremos satisfeitos em ajudar
              </div>
              <label
                htmlFor="labelFaleConoscoMotivo"
                className="labelFaleConoscoMotivo"
              >
                Motivo do contado
              </label>
              <select
                value={motivo.motivoId}
                onChange={(e) => {
                  setMotivo((prev) => ({ ...prev, motivoId: e.target.value }));
                }}
                className="inputPrimary"
              >
                <option value="">Selecione</option>
                {listaMotivo?.map((e, i) => (
                  <option key={i} value={e.faleConoscoAssuntoId}>
                    {e.descricao}
                  </option>
                ))}
              </select>

              <label
                htmlFor="labelFaleConoscoMotivoMsg"
                className="labelFaleConoscoMotivoMsg"
              >
                Escreva sua mensagem
              </label>
              <div className="faleConoscoTextArea overflow-hidden">
                <textarea
                  className="faleConoscoTextArea"
                  placeholder="Escreva sua mensagem"
                  value={motivo.mensagem}
                  onChange={(e) => {
                    setMotivo((prev) => ({
                      ...prev,
                      mensagem: e.target.value,
                    }));
                  }}
                ></textarea>
              </div>
              <button
                className="btn btn-primary w-100 mt-4"
                onClick={fnEnviar}
                disabled={
                  motivo.mensagem == "" || motivo.motivoId == "" || trava
                }
              >
                Enviar
              </button>
              <button
                className="btn btn-secondary w-100 mt-5"
                onClick={() => {
                  navigate("/fale-conosco-historico");
                }}
              >
                Histórico
              </button>
            </div>
          ) : (
            <div className="px-3">
              <div className="faleConoscoTexto1">Muito obrigado!</div>
              <div className="faleConoscoTexto2">
                Em breve, um de nossos atendentes entrará em contato.
              </div>

              <button
                className="btn btn-secondary w-100"
                style={{ marginTop: 268 }}
                onClick={() => {
                  navigate("/fale-conosco-historico");
                }}
              >
                Histórico
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
