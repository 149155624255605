import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import "./index.css";
import { slide as MenuBurger } from "react-burger-menu";
import storage from "../../services/storage";
import Mask from "../../services/mask";
import logo from "../../assets/images/png/logo.png";
import iconMenu from "../../assets/images/png/iconMenu.png";
import Avatar from "../Avatar";
import papel from "../../assets/images/svg/papelNotificacao.svg";
import sino from "../../assets/images/svg/sinoNotificacao.svg";
import exclamacao from "../../assets/images/svg/exclamacao.svg";
import { toast } from "react-toastify";

export default function Menu() {
  const { setLoad } = useLoad();
  const [eu, setEu] = useState([]);
  const [pontosSaldo, setPontosSaldo] = useState(0);
  const [pontosCreditados, setPontosCreditados] = useState(0);
  const [pontos, setPontos] = useState([]);
  const [notificacoesListaFaleConosco, setNotificacoesListaFaleConosco] =
    useState([]);
  const [notificacoesListaNoticias, setNotificacoesListaNoticias] = useState(
    []
  );

  const [abrirNotificacaoFaleConosco, setAbrirNotificacaoFaleConosco] =
    useState(false);
  const [abrirNotificacaoNoticias, setAbrirNotificacaoNoticias] =
    useState(false);
  const navigate = useNavigate();
  async function fnUsuario() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");
      setEu(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPontos() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("campanha/meu-resumo");
      setPontos(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnVizualizarNotificacoes(id, categoria) {
    var objEnvio = {
      ids: [id],
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPut(
        "notificacoes/marcar/lidos",
        objEnvio
      );

      categoria == "faleConosco" && navigate("/fale-conosco-historico");
      categoria == "noticias" && navigate("/noticia");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnNotificacoesFaleConosco() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet(
        "notificacoes?tipo=faleConosco"
      );
      setNotificacoesListaFaleConosco(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnNotificacoesNoticias() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("notificacoes?tipo=noticia");
      setNotificacoesListaNoticias(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPerformanceResumo() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("performance/meu-resumo");
      setPontosCreditados(response.data.pontosGanhos);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  // async function fnPontosExtract() {
  //   try {
  //     setLoad(true);
  //     const response = await ApiServices.apiPostCatalogo("extract");
  //     setPontosCreditados(response.creditBalance);
  //   } catch (err) {
  //     console.error("err", err);
  //   } finally {
  //     setLoad(false);
  //   }
  // }
  async function fnPontosBalance() {
    try {
      setLoad(true);
      const response = await ApiServices.apiPostCatalogo("balance");
      setPontosSaldo(response.balance);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnUsuario();
    fnPontos();
    fnNotificacoesFaleConosco();
    fnNotificacoesNoticias();
    // fnPontosExtract();
    fnPerformanceResumo();
    fnPontosBalance();
  }, []);

  function obterLetras(palavras) {
    // Divide a string em palavras com base nos espaços em branco
    const partes = palavras.split(" ");

    if (partes.length >= 1) {
      const primeiraPalavra = partes[0];
      const primeiraLetra = primeiraPalavra[0];

      if (partes.length >= 2) {
        const segundaPalavra = partes[1];
        return primeiraLetra + segundaPalavra[0];
      } else {
        return primeiraPalavra.slice(0, 2);
      }
    }
    return null; // Retorna nulo se a string estiver vazia
  }
  return (
    <div style={{ backgroundColor: "#25293F", height: 60, color: "#DEE3EB" }}>
      <div className="d-flex justify-content-center position-relative">
        <div className="w-100" style={{ maxWidth: 500 }}>
          <div
            className="d-flex justify-content-between w-100 align-items-center px-3"
            style={{ height: 60 }}
          >
            <div>Bem-vindo!</div>
            <div
              className="d-flex  align-items-center "
              style={{ paddingRight: 60 }}
            >
              {window.location.pathname === "/home" && (
                <div
                  className="alertaNoticidacaoIcone me-3"
                  onClick={() => {
                    setAbrirNotificacaoNoticias(true);
                  }}
                >
                  <div className="alertaNotificacao">
                    {" "}
                    {notificacoesListaNoticias?.length > 9
                      ? 9 + "+"
                      : notificacoesListaNoticias?.length}
                  </div>
                  <img
                    style={{ paddingLeft: 3 }}
                    className="me-4"
                    src={papel}
                    alt=""
                  />
                </div>
              )}
              {window.location.pathname === "/home" && (
                <div
                  className="alertaNoticidacaoIcone"
                  onClick={() => {
                    setAbrirNotificacaoFaleConosco(true);
                  }}
                >
                  <div className="alertaNotificacao">
                    {notificacoesListaFaleConosco?.length > 9
                      ? 9 + "+"
                      : notificacoesListaFaleConosco?.length}
                  </div>
                  <img
                    className=""
                    style={{ marginTop: -3, paddingLeft: 8 }}
                    src={sino}
                    alt=""
                  />
                </div>
              )}
              <div>
                <MenuBurger
                  right
                  className="bm-burger-bars-Deslogado"
                  customBurgerIcon={<img src={iconMenu} alt="" />}
                >
                  <img width={100} src={logo} alt="" />
                  <br />
                  <div className="cardPerfilMenu  ">
                    <div className="divAvatarMenu pointer">
                      <Avatar nome={eu.nome} font={25} rota={"/meu-cadastro"} />
                    </div>

                    <div className="divInfoCardMenu">
                      <div className="nomeCardPerfilMenu">{eu.nome}</div>
                      <div className="filialCardPerfil">
                        Filial {eu?.dealer?.nomeFantasia}
                      </div>

                      <hr />

                      <div className="d-flex ">
                        {/* <div>
                          <div className="pontosGanhosMenu">
                            Total de pontos creditados
                          </div>
                          <div className="pontosPerfilMenu">
                            {pontosCreditados?.toLocaleString("pt-br", {
                              minimumFractionDigits: 2,
                            })}
                          </div>
                        </div> */}
                        <div className="">
                          <div className="saldoAtualMenu">Saldo atual</div>

                          <div className="pontosPerfilMenu">
                            {pontosSaldo?.toLocaleString("pt-br", {
                              minimumFractionDigits: 2,
                            })}
                          </div>
                        </div>
                      </div>

                      {/* <div className="d-flex ">
                        <div>
                          <div className="pontosGanhosMenu">Pontos ganhos</div>
                          <div className="pontosPerfilMenu">
                            {Mask.numeroPontos(pontos.pontosGanhos)}
                          </div>
                        </div>
                        <div className="ms-4">
                          <div className="saldoAtualMenu">Total vendido</div>
                          <div className="pontosPerfilMenu">
                            {Mask.numeroPontos(pontos.totalVendido)}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <br />
                  <Link to="/home">
                    <div className="botaoMenu">
                      <span>Pagina inicial</span>
                    </div>
                  </Link>
                  <Link to="/meu-cadastro">
                    <div className="botaoMenu">
                      <span>Meu cadastro</span>
                    </div>
                  </Link>
                  <Link to="/performance">
                    <div className="botaoMenu">
                      <span>Performance</span>
                    </div>
                  </Link>

                  <Link to="/catalogo">
                    <div className="botaoMenu">
                      <span>Catálogo</span>
                    </div>
                  </Link>
                  <Link to="/campanhas">
                    <div className="botaoMenu">
                      <span>Campanhas</span>
                    </div>
                  </Link>
                  <Link to="/noticia">
                    <div className="botaoMenu">
                      <span>Institucional</span>
                    </div>
                  </Link>
                  <Link to="/perguntas-frequentes">
                    <div className="botaoMenu">
                      <span>Perguntas frequentes</span>
                    </div>
                  </Link>
                  <Link to="/fale-conosco">
                    <div className="botaoMenu">
                      <span>Fale conosco</span>
                    </div>
                  </Link>
                  <Link to="/regulamento">
                    <div className="botaoMenu">
                      <span>Termo de uso</span>
                    </div>
                  </Link>
                  <Link to="/como-funciona">
                    <div
                      className="botaoMenu"
                      style={{
                        borderBottom: "none",
                        marginBottom: 50,
                      }}
                    >
                      <span>Como funciona</span>
                    </div>
                  </Link>

                  <Link
                    to="/"
                    onClick={() => {
                      storage.del("USUARIO");
                    }}
                  >
                    <button
                      className="btn btn-secondary w-100"
                      style={{
                        padding: 0,
                        minHeight: 40,
                        fontSize: 13,
                        fontWeight: 700,
                      }}
                    >
                      Sair
                    </button>
                  </Link>
                </MenuBurger>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>

      {abrirNotificacaoFaleConosco && (
        <div
          className="bgNotificacoes d-flex justify-content-center "
          onClick={() => {
            setAbrirNotificacaoFaleConosco(false);
          }}
        >
          <div className="listaNotificacao">
            <>
              {notificacoesListaFaleConosco.map((e, i) => (
                <div
                  key={i}
                  className="divNotificacao"
                  onClick={(ele) => {
                    ele.stopPropagation();
                    fnVizualizarNotificacoes(e.notificacaoId, "faleConosco");
                    setAbrirNotificacaoFaleConosco(false);
                  }}
                >
                  <img
                    className="imgNotificacao"
                    src={exclamacao}
                    alt="exclamacao"
                  />
                  <div className="d-flex flex-column ">
                    <div className="textoNotificacao">{e.mensagem}</div>
                  </div>
                </div>
              ))}
              {notificacoesListaFaleConosco.length == 0 && (
                <h3 className="text-center ">Não tem notificações</h3>
              )}
            </>
          </div>
        </div>
      )}
      {abrirNotificacaoNoticias && (
        <div
          className="bgNotificacoes d-flex justify-content-center "
          onClick={() => {
            setAbrirNotificacaoNoticias(false);
          }}
        >
          <div className="listaNotificacao">
            <>
              {notificacoesListaNoticias.map((e, i) => (
                <div
                  key={i}
                  className="divNotificacao"
                  onClick={(ele) => {
                    ele.stopPropagation();
                    fnVizualizarNotificacoes(e.notificacaoId, "noticias");
                    setAbrirNotificacaoNoticias(false);
                  }}
                >
                  <img
                    className="imgNotificacao"
                    src={exclamacao}
                    alt="exclamacao"
                  />
                  <div className="d-flex flex-column ">
                    <div className="textoNotificacao">{e.mensagem}</div>
                  </div>
                </div>
              ))}
              {notificacoesListaNoticias.length == 0 && (
                <h3 className="text-center ">Não tem notificações</h3>
              )}
            </>
          </div>
        </div>
      )}
    </div>
  );
}
