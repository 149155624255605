import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";

export default function EmailCadastro() {
  const { load, setLoad } = useLoad();

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [trava, setTrava] = useState(false);
  async function fnEnviarEmail() {
    setTrava(true);
    var obj = {
      userName: storage.get("USUARIO")?.userName,
      email: storage.get("USUARIO")?.email,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "duplo-fator/deslogado/enviar/email",
        obj
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        navigate("/email-verificacao");
      } else {
        navigate("/cpf-cadastro");
        toast.error(data.message);
      }
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  function ocultarEmail(email) {
    const parts = email?.split("@");
    const prefix = parts[0];
    const obscuredPrefix =
      prefix?.substring(0, 2) + "*".repeat(prefix.length - 2);
    return obscuredPrefix + "@" + parts[1];
  }

  return (
    <>
      <div className="imgBackground"></div>{" "}
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 500 }}>
          <div className="px-5">
            <Link to={"/cpf-cadastro"}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="emailVerificacaoTexto1">Legal!</div>
            <div className="emailVerificacaoTexto2">
              <div style={{ marginBottom: 10 }}>
                Precisamos agora que confirme seu e-mail ok?
              </div>
              <div>
                Confirme abaixo o e-mail cadastrado:
                <b>
                  {" "}
                  {storage.get("USUARIO")?.email
                    ? ocultarEmail(storage.get("USUARIO")?.email)
                    : "E-mail não localizado"}
                </b>
              </div>
            </div>
            <label
              htmlFor="labelEmailVerificacao"
              className="labelEmailVerificacao"
            >
              Informe seu e-mail
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelEmailVerificacao"
                className="inputPrimary"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div
                className="labelLoginCpf m-0"
                style={{ color: "#FF3E0E", fontWeight: 600 }}
              >
                {storage.get("USUARIO")?.email === email
                  ? " "
                  : email?.length > 5
                  ? "E-mail invalido"
                  : " "}
              </div>
            </form>
            <div className="emailVerificacaoTexto3">
              Vamos enviar por e-mail um código de validação
            </div>

            <button
              onClick={() => {
                navigate("/email-recadastro-login");
              }}
              className="btn btn-secondary w-100 mb-5 "
              disabled={storage.get("USUARIO")?.email == email || trava}
            >
              Não reconheço este e-mail
            </button>
            <button
              onClick={fnEnviarEmail}
              className="btn btn-primary w-100 "
              disabled={storage.get("USUARIO")?.email !== email || trava}
            >
              Enviar código E-mail
            </button>
            {/* <div className="emailVerificacaoTexto4">
          Se não reconhece esse e-mail, entre em contato com seu gerente para
          maiores informações.
        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
