import React, { useEffect, useState } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { useLocation, useNavigate, useParams } from "react-router";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import pdfff from "../../assets/pdf/hyundai.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function PDF() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.state.url;

  console.log("url", url);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return url ? (
    <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
  ) : (
    <div>Carregando...</div>
  );
}
