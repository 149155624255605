import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import lupa from "../../assets/images/png/lupa.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { toast } from "react-toastify";
import moment from "moment";
import storage from "../../services/storage";
export default function FaleConoscoDetalhe() {
  const { load, setLoad } = useLoad();
  const [trava, setTrava] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [historicoLista, setHistoricoLista] = useState([]);

  const navigate = useNavigate();

  async function fnListarHistorico() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet(
        "faleconosco?participanteId=" +
          storage.get("USUARIO")?.profile?.userId +
          "&pageSize=9999"
      );
      const promises = response.data.items.map(async (e) => {
        return await fnListarHistoricoId(e.faleConoscoId);
      });
      const results = await Promise.all(promises);
      setHistoricoLista(results);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListarHistoricoId(id) {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("faleconosco/" + id);
      return response.data;
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarHistorico();
  }, []);
  return (
    <>
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/fale-conosco"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>

              <div className="tituloLogado">Fale conosco</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div className="w-100" style={{ maxWidth: 500 }}>
          <div className="faleConoscoDetalheTexto1">Histórico</div>
        </div>
      </div>
      <div className="px-3">
        <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
          <div
            className="d-flex flex-column align-items-center w-100"
            style={{ maxWidth: 500 }}
          >
            {historicoLista.reverse().map((e, i) => (
              <div
                className="divCardFaleConoscoDetalhe overflow-hidden "
                key={i}
              >
                <div>
                  <div className="d-flex ">
                    <div className="faleConoscoDetalheTexto2 w-50 ">
                      Data de criação:
                    </div>
                    <div className="faleConoscoDetalheTexto3 w-50 ">
                      {moment(e.dataHora).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="faleConoscoDetalheTexto2 w-50 ">
                      Motivo do contato:
                    </div>
                    <div className="faleConoscoDetalheTexto3 w-50 ">
                      {e.assunto}
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="faleConoscoDetalheTexto2 w-50 ">
                      Mensagem enviada:
                    </div>
                    <div className="faleConoscoDetalheTexto3 w-50 ">
                      {e.respostas[0].mensagem}
                    </div>
                  </div>
                  {e.respostas[1] && (
                    <div>
                      <div className="d-flex ">
                        <div className="faleConoscoDetalheTexto2 w-50 ">
                          Data da resposta:
                        </div>
                        <div className="faleConoscoDetalheTexto3 w-50 ">
                          {" "}
                          {e.respostas[1]
                            ? moment(e.respostas[1]?.dataCriacao).format(
                                "DD/MM/YYYY"
                              )
                            : "aguardando..."}
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="faleConoscoDetalheTexto2 w-50 ">
                          Resposta:
                        </div>
                        <div className="faleConoscoDetalheTexto3 w-50 ">
                          {e.respostas[1]
                            ? e.respostas[1]?.mensagem
                            : "aguardando..."}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
