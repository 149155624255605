import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import storage from "../../services/storage";
import { Link } from "react-router-dom";
import { validaEmail } from "../../services/validaEmail";
import { toast } from "react-toastify";

export default function EsqueceuSenhaConfirmacao() {
  const { load, setLoad } = useLoad();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  async function fnEnviar() {
    var objEnvio = {
      email: email,
      userName: storage.get("USUARIO").cpf,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/senha/resetar/email",
        objEnvio
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        navigate("/esqueci-minha-senha/confirmacao/sucesso");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground"></div>
      <div className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-start w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-5">
            <Link onClick={() => window.history.back()} className="invisible ">
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="esqueciConfirmacaoTexto1">
              Confirmação
            </div>
            <div className="esqueciConfirmacaoTexto2">
              Vamos enviar a recuperação de senha para seu e-mail, tudo bem?
            </div>
            <br />
            <div className="esqueciConfirmacaoTexto2">
              Confirme abaixo o e-mail cadastrado.
              <br />
              <b>{storage.get("USUARIO").email}</b>
            </div>
            <form autoComplete="off" className="mt-5">
              <input
                autoComplete="off"
                name="labelEmailVerificacao"
                className="inputPrimary"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div
                className="labelLoginCpf m-0"
                style={{ color: "#FF3E0E", fontWeight: 600 }}
              >
                {validaEmail(email)
                  ? " "
                  : email?.length > 5
                  ? "E-mail invalido"
                  : " "}
              </div>
            </form>
            <button
              onClick={() => {
                // navigate("/");
                fnEnviar();
              }}
              className="btn btn-primary w-100"
              disabled={!validaEmail(email)}
              style={{ marginTop: 50 }}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
