import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import ForcaSenha from "../../Components/ForcaSenha";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { validaCpf } from "../../services/validaCpf";
export default function RecriarSenha() {
  const { load, setLoad } = useLoad();
  const [validacao, setValidacao] = useState(false);
  const [trava, setTrava] = useState(false);
  const [recriarSenha, setRecriarSenha] = useState({
    cpf: "",
    codigo: "",
    senha: "",
    confirmarSenha: "",
  });
  const navigate = useNavigate();

  async function fnAlterarSenha() {
    setTrava(true);
    try {
      setLoad(true);
      var obj = {
        userName: recriarSenha.cpf,
        codigo: recriarSenha.codigo,
        password: recriarSenha.senha,
      };
      let response = await ApiServices.apiPost("usuario/senha/alterar", obj)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 200) {
            toast.success("Senha alterada com sucesso!");
            navigate("/");
          } else {
            toast.error(data.message);
          }
        });
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    setRecriarSenha((prev) => ({
      ...prev,
      cpf: Mask.unMask(storage.get("cpf")),
    }));
  }, []);

  return (
    <>
      <div className="imgBackground"> </div>
      <div className="d-flex justify-content-center flex-column align-items-center  ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-5">
            <Link onClick={() => window.history.back()}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="recriarSenhaTexto1">Recrie a sua senha</div>
            <div className="recriarSenhaTexto2"></div>
            <label
              htmlFor="labelRecriarSenhaCpf"
              className="labelRecriarSenhaCpf"
            >
              CPF
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelRecriarSenhaCpf"
                className="inputPrimary"
                placeholder="000.000.000-00"
                inputMode="numeric"
                maxLength={14}
                type="text"
                value={Mask.cpf(recriarSenha.cpf)}
                onChange={(e) => {
                  setRecriarSenha((prev) => ({
                    ...prev,
                    cpf: Mask.unMask(e.target.value),
                  }));
                }}
              />
            </form>
            <label
              htmlFor="labelRecriarSenhaToken"
              className="labelRecriarSenhaToken"
            >
              Codigo
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelRecriarSenhaToken"
                className="inputPrimary"
                inputMode="numeric"
                type="text"
                value={recriarSenha.codigo}
                onChange={(e) => {
                  setRecriarSenha((prev) => ({
                    ...prev,
                    codigo: e.target.value,
                  }));
                }}
              />
            </form>
            <label
              htmlFor="labelRecriarSenhaSenha"
              className="labelRecriarSenhaSenha"
            >
              Senha
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelRecriarSenhaSenha"
                className="inputPrimary"
                type="password"
                value={recriarSenha.senha}
                onChange={(e) => {
                  setRecriarSenha((prev) => ({
                    ...prev,
                    senha: e.target.value,
                  }));
                }}
              />
            </form>

            <label
              htmlFor="labelRecriarSenhaConfirmarSenha"
              className="labelRecriarSenhaConfirmarSenha"
            >
              Confirmar senha
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelConfirmalabelRecriarSenhaConfirmarSenharSenha"
                className="inputPrimary"
                type="password"
                value={recriarSenha.confirmarSenha}
                onChange={(e) => {
                  setRecriarSenha((prev) => ({
                    ...prev,
                    confirmarSenha: e.target.value,
                  }));
                }}
              />
            </form>
            <div style={{ marginTop: 20 }}>
              <ForcaSenha
                senha={recriarSenha.senha}
                confirmarSenha={recriarSenha.confirmarSenha}
                setValidacao={setValidacao}
              />
            </div>
            <button
              disabled={
                !validacao ||
                recriarSenha.codigo.length < 4 ||
                trava ||
                !validaCpf(recriarSenha.cpf)
              }
              onClick={fnAlterarSenha}
              className="btn btn-primary w-100 "
              style={{ margin: "40px 0" }}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
