import React, { useEffect, useState } from "react";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
var estadoAnterior = 0;

export default function ForcaSenha({ senha, confirmarSenha, setValidacao }) {
  const [forca, setForca] = useState(0);
  const [ea, setEa] = useState("inicio");
  const [validaSenha, setValidaSenha] = useState([false, false, false, false]);

  useEffect(() => {
    if (forca === 4 && senha === confirmarSenha) {
      setValidacao(true);
    } else {
      setValidacao(false);
    }
  }, [forca, senha, confirmarSenha]);

  function fnSenha() {
    if (senha.length >= 8) {
      validaSenha[0] = true;
    } else {
      validaSenha[0] = false;
    }

    if (senha.replace(/[^0-9]/g, "").length > 0) {
      validaSenha[1] = true;
    } else {
      validaSenha[1] = false;
    }

    if (
      senha.replace(/[^a-z]/g, "").length > 0 &&
      senha.replace(/[^A-Z]/g, "").length > 0
    ) {
      validaSenha[2] = true;
    } else {
      validaSenha[2] = false;
    }

    if (senha.replace(/[a-zA-Z0-9]/g, "").length > 0) {
      validaSenha[3] = true;
    } else {
      validaSenha[3] = false;
    }

    var num = 0;
    for (var x = 0; x <= validaSenha.length; x++) {
      if (validaSenha[x] == true) num++;
    }
    setForca(num);
  }

  useEffect(() => {
    if (forca > estadoAnterior) {
      setEa("mais");
      estadoAnterior = forca;
    }
    if (forca < estadoAnterior) {
      setEa("menos");
      estadoAnterior = forca;
    }
  }, [forca]);
  useEffect(() => {
    if (senha != null && senha != undefined) {
      fnSenha();
    }
  }, [senha]);

  return (
    <>
      <div style={{ marginTop: 10 }}>Força da sua senha</div>
      <div className="barras">
        <div className="bar">
          <div
            className={
              forca == 0 && ea == "inicio"
                ? "barraVazia bar1 "
                : forca == 1 && ea == "mais"
                ? "barraEnchendo bar1"
                : forca == 0 && ea == "menos"
                ? "barraEsvaziando bar1"
                : forca == 1
                ? "barraCheia bar1 "
                : forca == 2
                ? "barraCheia bar2 "
                : forca == 3
                ? " barraCheia bar3 "
                : " barraCheia bar4 "
            }
          ></div>
        </div>
        <div className="bar">
          <div
            className={
              forca == 2 && ea == "mais"
                ? "barraEnchendo bar2"
                : forca == 1 && ea == "menos"
                ? "barraEsvaziando bar2"
                : forca == 2
                ? "barraCheia bar2"
                : forca == 3
                ? "barraCheia bar3"
                : forca == 4
                ? "barraCheia bar4"
                : "bar2"
            }
          ></div>
        </div>
        <div className="bar">
          <div
            className={
              forca == 3 && ea == "mais"
                ? "barraEnchendo bar3 "
                : forca == 2 && ea == "menos"
                ? "barraEsvaziando bar3"
                : forca == 3
                ? "barraCheia bar3"
                : forca == 4
                ? "barraCheia bar4"
                : "bar3"
            }
          ></div>
        </div>
        <div className="bar">
          <div
            className={
              forca == 4 && ea == "mais"
                ? "barraEnchendo bar4 "
                : forca == 3 && ea == "menos"
                ? "barraEsvaziando bar4"
                : forca >= 4
                ? "barraCheia bar4"
                : " bar4"
            }
          ></div>
        </div>
      </div>

      <div
        style={{
          marginTop: 5,
          textAlign: "end",
          fontWeight: 700,
          color:
            forca == 1
              ? "rgb(255, 0, 0)"
              : forca == 2
              ? "rgb(255, 127, 0)"
              : forca == 3
              ? "rgb(255, 255, 0)"
              : "rgb(0, 255, 0)",
        }}
      >
        {forca == 1
          ? "Fraca"
          : forca == 2
          ? "Média"
          : forca == 3
          ? "Média"
          : forca == 4
          ? "Forte"
          : " "}
      </div>
      <div className="row mt-2">
        <div className="d-flex align-items-center textoSenha">
          {senha.length >= 8 ? (
            <CheckIcon style={{ color: "green", marginTop: -3 }} />
          ) : (
            <CloseIcon style={{ color: "red", marginTop: -3 }} />
          )}{" "}
          Conter no mínimo 8 caracteres
        </div>
        <div className="d-flex align-items-center textoSenha">
          {senha.replace(/[^0-9]/g, "").length > 0 ? (
            <CheckIcon style={{ color: "green", marginTop: -3 }} />
          ) : (
            <CloseIcon style={{ color: "red", marginTop: -3 }} />
          )}{" "}
          Conter números{" "}
        </div>
        <div className="d-flex align-items-center textoSenha">
          {/[A-Z]/.test(senha) && /[a-z]/.test(senha) ? (
            <CheckIcon style={{ color: "green", marginTop: -3 }} />
          ) : (
            <CloseIcon style={{ color: "red", marginTop: -3 }} />
          )}{" "}
          Conter letras maiúsculas e minúsculas
        </div>

        <div className="d-flex align-items-center textoSenha">
          {senha.replace(/[a-zA-Z0-9]/g, "").length > 0 ? (
            <CheckIcon style={{ color: "green", marginTop: -3 }} />
          ) : (
            <CloseIcon style={{ color: "red", marginTop: -3 }} />
          )}{" "}
          Conter caracteres especiais
        </div>

        <div className="d-flex align-items-center textoSenha">
          {senha === confirmarSenha && senha != "" ? (
            <CheckIcon style={{ color: "green", marginTop: -3 }} />
          ) : (
            <CloseIcon style={{ color: "red", marginTop: -3 }} />
          )}{" "}
          Confirmação de senha igual
        </div>
      </div>
    </>
  );
}
