import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { validaCpf } from "../../services/validaCpf";
import storage from "../../services/storage";

export default function EsqueceuSenha() {
  const { load, setLoad } = useLoad();
  const [cpf, setCpf] = useState("");
  const navigate = useNavigate();
  const [trava, setTrava] = useState(false);

  async function fnResetar() {
    setTrava(true);
    try {
      var obj = {
        userName: cpf,
      };
      setLoad(true);
      let response = await ApiServices.apiPost("usuario/confirmar/email", obj)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 200) {
            const atualizado = Object.assign(data.data, { cpf: cpf });
            storage.set("USUARIO", atualizado);

            navigate("/esqueci-minha-senha/confirmacao");
          } else {
            toast.error(data.message);
            setCpf("");
          }
        });

      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground"> </div>

      <div className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-start w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-5">
            <Link onClick={() => window.history.back()}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="esqueciTexto1">Esqueceu a senha?</div>
            <div className="esqueciTexto2">
              Vamos enviar a recuperação de senha para seu e-mail cadastrado,
              tudo bem?
            </div>
            <label htmlFor="labelEsqueciCpf" className="labelEsqueciCpf">
              CPF
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                name="labelEsqueciCpf"
                className="inputPrimary"
                placeholder="000.000.000-00"
                maxLength={14}
                inputMode="numeric"
                type="text"
                value={Mask.cpf(cpf)}
                onChange={(e) => {
                  setCpf(Mask.unMask(e.target.value));
                }}
              />
              <div
                className="labelLoginCpf m-0"
                style={{ color: "#FF3E0E", fontWeight: 600 }}
              >
                {validaCpf(cpf) || cpf.length < 11 ? " " : "CPF invalido"}{" "}
              </div>
            </form>

            <button
              onClick={fnResetar}
              className="btn btn-primary w-100 "
              style={{ marginTop: 30 }}
              disabled={!validaCpf(cpf) || cpf.length != 11 || trava}
            >
              Enviar
            </button>

            <div className="esqueciTexto3">
              Caso você não possua mais acesso ao e-mail vinculado à sua conta,
              entre em contato com seu consultor.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
