import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";

export default function EmailRecadastroVerificacao() {
  const { load, setLoad } = useLoad();
  const [codigo, setCodigo] = useState("");
  const [tempo, setTempo] = useState(120);

  const navigate = useNavigate();

  async function fnReenviarCodigo() {
    var obj = {
      codigoLogin: storage.get("USUARIO")?.login,
      email: storage.get("USUARIO")?.email,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "duplo-fator/deslogado/codigo/login/enviar/email",
        obj
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success(data.data);
        setTempo(120);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnCodigo(id) {
    var obj = {
      codigoLogin: storage.get("USUARIO")?.login,
      email: storage.get("USUARIO")?.email,
      codigoDuploFator: id,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/codigo/login/email/confirmar",
        obj
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success(data.data);
        navigate("/email-recadastro-verificado");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  function fnInserirNumero(e) {
    if (e.target.value === "" && e.target.previousElementSibling !== null) {
      e.target.previousElementSibling.focus();
    } else {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }

    if (
      e.target.value !== "" &&
      e.target.nextElementSibling &&
      e.target.nextElementSibling.nodeName === "INPUT"
    ) {
      e.target.nextElementSibling.focus();
    }

    var i1 =
      document.querySelectorAll(".emailRecadastroVerificacaoInputCard")[0]
        .value || "";
    var i2 =
      document.querySelectorAll(".emailRecadastroVerificacaoInputCard")[1]
        .value || "";
    var i3 =
      document.querySelectorAll(".emailRecadastroVerificacaoInputCard")[2]
        .value || "";
    var i4 =
      document.querySelectorAll(".emailRecadastroVerificacaoInputCard")[3]
        .value || "";
    var i5 =
      document.querySelectorAll(".emailRecadastroVerificacaoInputCard")[4]
        .value || "";
    var i6 =
      document.querySelectorAll(".emailRecadastroVerificacaoInputCard")[5]
        .value || "";
    var todosI = i1 + i2 + i3 + i4 + i5 + i6;
    if (todosI.length === 6) {
      fnCodigo(todosI);
    }
  }

  function fnColarNumero(ev) {
    let paste = ev.clipboardData.getData("text");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (tempo > 0) {
        setTempo(tempo - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [tempo]);

  useEffect(() => {}, []);
  return (
    <>
      <div className="imgBackground"> </div>
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="px-5">
            <Link to={"/email-recadastro-email"}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="emailRecadastroVerificacaoTexto1">Verificação</div>
            <div className="emailRecadastroVerificacaoTexto2">
              Digite o código que enviamos por e-mail
            </div>

            <div className="d-flex justify-content-center emailRecadastroVerificacaoInputCardTodos ">
              <input
                className="emailRecadastroVerificacaoInputCard"
                type="text"
                maxLength="1"
                name="digit1"
                onChange={fnInserirNumero}
                onPaste={fnColarNumero}
                inputMode="numeric"
              />
              <input
                className="emailRecadastroVerificacaoInputCard"
                type="text"
                maxLength="1"
                name="digit2"
                onChange={fnInserirNumero}
                onPaste={fnColarNumero}
                inputMode="numeric"
              />
              <input
                className="emailRecadastroVerificacaoInputCard"
                type="text"
                maxLength="1"
                name="digit3"
                onChange={fnInserirNumero}
                onPaste={fnColarNumero}
                inputMode="numeric"
              />
              <input
                className="emailRecadastroVerificacaoInputCard"
                type="text"
                maxLength="1"
                name="digit4"
                onChange={fnInserirNumero}
                onPaste={fnColarNumero}
                inputMode="numeric"
              />
              <input
                className="emailRecadastroVerificacaoInputCard"
                type="text"
                maxLength="1"
                name="digit5"
                onChange={fnInserirNumero}
                onPaste={fnColarNumero}
                inputMode="numeric"
              />
              <input
                className="emailRecadastroVerificacaoInputCard"
                type="text"
                maxLength="1"
                name="digit6"
                onChange={fnInserirNumero}
                onPaste={fnColarNumero}
                inputMode="numeric"
              />
            </div>

            <div className="emailRecadastroVerificacaoTexto3">
              Aguarde <b>{tempo} segundos </b>
              para reenviar o código
            </div>
            <br />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary "
                disabled={tempo !== 0}
                onClick={() => {
                  fnReenviarCodigo();
                }}
              >
                Reenviar o código
              </button>
            </div>
            <br />
            <div className="emailRecadastroVerificacaoTexto4 text-center">
              Nunca pedimos este dado por e-mail, SMS ou telefone. Não
              compartilhe este código com ninguém.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
