import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";
import smileUser from "../../assets/images/svg/smileUser.svg";
import smileFeliz from "../../assets/images/svg/smileFeliz.svg";
import setaVisualizar from "../../assets/images/png/setaDrop.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify";
export default function Performance() {
  const { load, setLoad } = useLoad();
  const [performance, setPerformance] = useState([]);
  const [pontosSaldo, setPontosSaldo] = useState(0);
  const [resgate, setResgate] = useState(0);
  const [listaPontuacao, setListaPontuacao] = useState([]);
  const [verMaisPontuacao, setVerMaisPontuacao] = useState(5);
  const [ano, setAno] = useState([]);
  const [visualizar, setVisualizar] = useState(false);
  const [mes, setMes] = useState([]);
  const [anoMes, setAnoMes] = useState({ ano: "", mes: "" });
  const [meuResumoPerformance, setMeuResumoPerformance] = useState({});

  const [listaExtrato, setListaExtrato] = useState([
    {
      dataHora: "30/11/2023 22:51",
      descricao: "Crédito",
      pontosGanhos: "73",
    },
    {
      dataHora: "20/12/2024 22:51",
      descricao: "Debito",
      pontosGanhos: "88",
    },
    {
      dataHora: "10/01/2025 22:51",
      descricao: "Debito",
      pontosGanhos: "251",
    },
    {
      dataHora: "05/02/2026 22:51",
      descricao: "Debito",
      pontosGanhos: "73",
    },
    {
      dataHora: "30/03/2027 22:51",
      descricao: "Crédito",
      pontosGanhos: "141",
    },
  ]);
  const [verMaisExtrato, setVerMaisExtrato] = useState(5);

  async function fnAno() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("ano");
      setAno(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnMes() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("mes");
      setMes(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnMeuResumoPerformance() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet(
        "performance/meu-resumo?ano=" + anoMes.ano + "&mesId=" + anoMes.mes
      );
      setMeuResumoPerformance(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPerformance() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");
      setPerformance(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListaPontuacaoExtrato() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet(
        "apuracao/minha?ano=" +
          anoMes.ano +
          "&mesId=" +
          anoMes.mes +
          "&page=1&pageSize=" +
          verMaisPontuacao
      );
      setListaPontuacao(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnExtrair(id, nomeArquivo) {
    // const parametros = {
    //   q: ranking.pesquisar || "",
    //   participanteCargoId: ranking.cargoId || "",
    //   participanteGrupoId: ranking.grupoId || "",
    // };

    setLoad(true);
    try {
      // const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `performance/venda/minha/exportar?apuracaoId=${id}`,
        nomeArquivo
      );
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPontosBalance() {
    try {
      setLoad(true);
      const response = await ApiServices.apiPostCatalogo("balance");
      setPontosSaldo(response.balance);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPontosExtract() {
    try {
      setLoad(true);
      const response = await ApiServices.apiPostCatalogo("extract");

      setResgate(response);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  const [expandidoIndices, setExpandidoIndices] = useState([]);

  const toggleExpandido = (index) => {
    if (expandidoIndices.includes(index)) {
      setExpandidoIndices(expandidoIndices.filter((i) => i !== index));
    } else {
      setExpandidoIndices([...expandidoIndices, index]);
    }
  };
  useEffect(() => {
    fnListaPontuacaoExtrato();
  }, [verMaisPontuacao]);

  useEffect(() => {
    fnAno();
    fnMes();
    fnPerformance();
    fnPontosBalance();
    fnPontosExtract();
  }, []);

  useEffect(() => {
    fnMeuResumoPerformance();
    fnListaPontuacaoExtrato();
  }, [anoMes]);
  return (
    <div className="w-100 ">
      <div className="imgBackground"> </div>
      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/home"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Performance</div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <div style={{ width: 500 }}>
            <div className="performanceTitulo">Resumo - Catálogo</div>
            <div className="itensResumo w-100 d-flex justify-content-between px-4 my-3">
              <span>Pontos creditados</span>
              <b>
                {resgate?.creditBalance
                  ? resgate.creditBalance?.toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    })
                  : "Não disponível"}
              </b>
            </div>
            <div className="itensResumo w-100 d-flex justify-content-between px-4 my-3">
              <span>Pontos resgatados</span>
              <b>
                {resgate?.redeemBalance
                  ? resgate.redeemBalance?.toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    })
                  : "Não disponível"}
              </b>
            </div>
            <div className="itensResumo w-100 d-flex justify-content-between px-4 my-3">
              <b>Saldo de pontos</b>
              <b>
                {pontosSaldo
                  ? pontosSaldo?.toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    })
                  : "Não disponível"}
              </b>
            </div>
            <hr className="mt-5"></hr>
            <div className="performanceTitulo ">Resumo - Motor de pontos</div>
            <div className="d-flex justify-content-start">
              <div className="me-3 inputPrimaryPerformace">
                <label htmlFor="labelSobreCargo" className="labelSobreCargo">
                  Ano
                </label>

                <select
                  value={anoMes.ano}
                  onChange={(e) => {
                    setAnoMes((prev) => ({ ...prev, ano: e.target.value }));
                  }}
                  className="inputPrimary  "
                >
                  <option value="">Todos</option>
                  {ano?.map((e, i) => (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inputPrimaryPerformace">
                <label htmlFor="labelSobreCargo" className="labelSobreCargo">
                  Mês
                </label>

                <select
                  value={anoMes.mes}
                  onChange={(e) => {
                    setAnoMes((prev) => ({ ...prev, mes: e.target.value }));
                  }}
                  className="inputPrimary  "
                >
                  <option value="">Todos</option>
                  {mes?.map((e, i) => (
                    <option key={i} value={e.mesId}>
                      {e.descricao}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="itensResumo w-100 d-flex justify-content-between px-4 my-3">
              <span>Total de vendas</span>
              <b>
                {meuResumoPerformance?.totalVendas?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </b>
            </div>
            <div className="itensResumo w-100 d-flex justify-content-between px-4 my-3">
              <span>Pontos ganhos</span>
              <b>
                {meuResumoPerformance?.pontosGanhos?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </b>
            </div>
            {(listaPontuacao?.items || []).length > 0 && (
              <div>
                <hr className="mt-5"></hr>
                <div className="performanceTitulo ">Pontuação</div>
              </div>
            )}
            {(listaPontuacao?.items || []).map((e, i) => (
              <div
                key={i}
                className="itensResumoPontuacao w-100 d-flex justify-content-between px-4 my-3"
              >
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="d-flex me-4">
                        <span style={{ width: "200px" }}>
                          Data de apuração:
                        </span>
                        <b>{moment(e.dataApuracao).format("DD/MM/YYYY")}</b>
                      </div>
                      <div className="d-flex me-4">
                        <span style={{ width: "200px" }}>Total de VINs:</span>
                        <b>{e.totalVins}</b>
                      </div>
                      <div className="d-flex me-4">
                        <span style={{ width: "200px" }}>Pontos Ganhos:</span>
                        <b>
                          {e.pontosGanhos?.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                        </b>
                      </div>
                    </div>
                    <div className="containerBtn mb-3">
                      <div
                        className="d-flex justify-content-end"
                        onClick={() => toggleExpandido(i)}
                      >
                        {expandidoIndices?.includes(i) ? (
                          <img
                            className="setaCima"
                            width={20}
                            src={setaVisualizar}
                            alt="seta virada para baixo"
                          />
                        ) : (
                          <img
                            className="setaBaixo"
                            width={20}
                            src={setaVisualizar}
                            alt="seta virada para cima"
                          />
                        )}
                      </div>
                      <div
                        className="itensResumoExportar d-flex align-items-center mt-3"
                        onClick={() => {
                          fnExtrair(
                            e.apuracaoId,
                            "relatorio da pontuação " +
                              moment(e.dataApuracao).format("DD/MM/YYYY")
                          );
                        }}
                      >
                        Exportar
                      </div>
                    </div>
                  </div>
                  {expandidoIndices.includes(i) &&
                    (e.chassis || []).map((vinPontuacao, j) => (
                      <div className="d-flex" key={j}>
                        <div className="d-flex me-4">
                          <span style={{ width: "86px" }}>Chassi:</span>
                          <b>{vinPontuacao.chassi}</b>
                        </div>
                        <div className="d-flex me-4">
                          <span style={{ width: "100px" }}>Pontuação:</span>
                          <b>{vinPontuacao.pontos}</b>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
            {(listaPontuacao?.items || []).length >= verMaisPontuacao && (
              <div className="d-flex justify-content-center mt-5">
                <button
                  className="btn btn-secondary w-50"
                  onClick={() => {
                    setVerMaisPontuacao(verMaisPontuacao + 5);
                  }}
                >
                  Ver mais
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
